import { Card } from '@mui/material';
import {
    clearShoppingPathwayMarketplacePlan,
    clearShoppingPathwayMedisharePrograms,
} from 'actions/clear';
import {
    GET_PATHWAY_MARKETPLACE_PLAN_FOR_PATHWAY_USER_ACTION,
    getMarketplacePlanRatesForPlans,
} from 'actions/marketplacePlan/getMarketplacePlanRatesForPlans';
import { GET_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION } from 'actions/marketplacePlan/getMarketplacePlanRatesForUser';
import {
    GET_MEDISHARE_PLANS_FOR_USER_ACTION,
    getMediSharePlansAndRatesForUser,
} from 'actions/medishare/getMediSharePlansAndRatesForUser';
import { PathwayTypes, PlanListing } from 'api/generated/enums';
import { IMarketplacePlanDto, IMediSharePlan } from 'api/generated/models';
import PathwayTitle from 'components/PathwayTitle';
import Skeleton from 'components/Skeleton';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import PlanCard, { IPlan, MEDISHARE_PLAN_NAME } from 'pages/shop/PlanCard';
import PlanCardHeader from 'pages/shop/PlanCardHeader';
import { getEligibilityInfo, hasAnyDisqualifyingEligibilities } from 'pages/shop/ShopHeader';
import { ShoppingContext } from 'pages/shop/shopPageUtilities';
import { IShoppingPlan } from 'pages/shop/shopping';
import React, { useContext, useEffect, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { IHouseholdMemberWithAge } from 'reducers/householdMembers';
import { GET_PATHWAY_MEDISHARE_PROGRAMS_FOR_USER_ACTION } from 'reducers/shoppingPathwayMediSharePrograms';
import { hasApiActivity, hasCompletedRequest } from 'selectors/activity';

const MEDICARE_AGE = 65;
const isMedicareEligible = (age: number): boolean => age >= MEDICARE_AGE;
const getSharingHouseholdMembersCovered = (householdMembers: IHouseholdMemberWithAge[]): string[] =>
    householdMembers
        .filter((x) => !isMedicareEligible(x.age) && x.needsCoverage)
        .map((x) => x.householdMemberId);

const PathwayPlanCard = ({
    handleSelectPlan,
    isIchraFlow,
    pathwayType,
    shouldShowTaxSavings = false,
}: {
    handleSelectPlan: (plan: Partial<IShoppingPlan>) => Promise<void>;
    isIchraFlow?: boolean;
    pathwayType: PathwayTypes;
    shouldShowTaxSavings?: boolean;
}) => {
    const isOnOrOffExchange = [
        PathwayTypes.MarketplaceCappedEmployeeCost,
        PathwayTypes.MarketplaceCappedEmployerCost,
        PathwayTypes.IchraCappedEmployeeCost,
        PathwayTypes.IchraCappedEmployerCost,
        PathwayTypes.IchraAgeBanded,
    ].includes(pathwayType);
    const {
        excludedHouseholdMembers,
        hasLoadedPlanCost,
        hasPendingPlanRequests,
        householdEligibility,
        householdMembers,
        medisharePrograms,
        pathwayPlan,
        pathwayUser,
    } = useSelector((state: AppStore) => ({
        excludedHouseholdMembers: state.shoppingState.disabledMembers,
        hasLoadedPlanCost: hasCompletedRequest(
            state,
            GET_PATHWAY_MARKETPLACE_PLAN_FOR_PATHWAY_USER_ACTION
        ),
        hasPendingPlanRequests: hasApiActivity(
            state,
            GET_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION,
            GET_MEDISHARE_PLANS_FOR_USER_ACTION
        ),
        householdEligibility: state.householdEligibility,
        householdMembers: state.householdMembers,
        medisharePrograms: state.shoppingPathwayMediSharePrograms,
        pathwayPlan: state.shoppingPathwayMarketplacePlan,
        pathwayUser: state.pathwayUser,
    }));
    let plan: Partial<IPlan>;
    if (isOnOrOffExchange) {
        plan = { isMarketplace: true, ...pathwayPlan[0] };
    } else {
        plan = {
            isMediShare: true,
            ...medisharePrograms.find((x) => x.ahp === pathwayUser?.planId),
        };
    }
    const pathwayMajorMedicalBenefitCostDto = pathwayPlan[0]?.majorMedicalBenefitCost;
    const dispatch = useThunkDispatch();
    const { hasFetchedPlans } = useContext(ShoppingContext);
    const { isInIchraPathway, memberVerifiedInfo, user, userId, yearlyUserInfo } = useUserProps();
    const householdMembersCovered = useMemo(
        () =>
            isOnOrOffExchange
                ? householdMembers
                      .filter(
                          (x) => !excludedHouseholdMembers.some((y) => y === x.householdMemberId)
                      )
                      .map((x) => x.householdMemberId)
                : getSharingHouseholdMembersCovered(householdMembers),
        [excludedHouseholdMembers, householdMembers, isOnOrOffExchange]
    );
    const currentContribution = user?.activeWageUps?.find(
        (x) => x.year === memberVerifiedInfo?.year
    );
    const getIsPrimaryCovered = () => {
        const {
            eligibilityStatus: primaryEligibilityStatus,
            otherEligibilityStatus,
        } = getEligibilityInfo(householdEligibility, userId);

        return (
            (!hasAnyDisqualifyingEligibilities(
                primaryEligibilityStatus,
                otherEligibilityStatus,
                isInIchraPathway ?? false
            ) &&
                yearlyUserInfo?.needsMajorMedicalCoverage) ??
            false
        );
    };

    const isOffExchangePlan = currentContribution?.benchmarkType === PlanListing.OffExchange;

    const isPrimaryCovered = getIsPrimaryCovered();
    const individualOnly = householdMembersCovered.length === (isPrimaryCovered ? 0 : 1);
    const showTaxSavingsTag = shouldShowTaxSavings && isOffExchangePlan;

    const header = <PathwayTitle pathwayType={pathwayType} />;
    const pathwayPlanCardHeader = useMemo(() => {
        if (isOnOrOffExchange) {
            const marketplacePlan = plan as IMarketplacePlanDto;
            return (
                <PlanCardHeader
                    carrier={marketplacePlan?.issuer?.name}
                    cost={marketplacePlan?.premium}
                    costWithCredit={marketplacePlan?.premium}
                    dataCyPrefix="pathway"
                    hidePlanCost
                    majorMedicalBenefitCostDto={pathwayMajorMedicalBenefitCostDto}
                    name={marketplacePlan?.name}
                    plan={plan}
                    showTaxSavings={showTaxSavingsTag}
                />
            );
        } else {
            const medisharePlan = plan as IMediSharePlan;
            return (
                <PlanCardHeader
                    carrier=""
                    cost={medisharePlan?.totalAmount}
                    costWithCredit={medisharePlan?.totalAmount}
                    dataCyPrefix="pathway"
                    hidePlanCost
                    majorMedicalBenefitCostDto={pathwayMajorMedicalBenefitCostDto}
                    name={MEDISHARE_PLAN_NAME}
                    plan={plan}
                />
            );
        }
    }, [isOnOrOffExchange, pathwayMajorMedicalBenefitCostDto, plan, showTaxSavingsTag]);

    useEffect(() => {
        if (isOnOrOffExchange) {
            if (!hasPendingPlanRequests) {
                dispatch(clearShoppingPathwayMarketplacePlan);
                dispatch(
                    getMarketplacePlanRatesForPlans(
                        userId,
                        [pathwayUser?.planId as string],
                        excludedHouseholdMembers,
                        user?.activeDate?.getYear() as number,
                        isOffExchangePlan,
                        false,
                        false,
                        GET_PATHWAY_MARKETPLACE_PLAN_FOR_PATHWAY_USER_ACTION
                    )
                );
            }
        } else {
            dispatch(clearShoppingPathwayMedisharePrograms);
            dispatch(
                getMediSharePlansAndRatesForUser(
                    userId,
                    user?.activeDate?.getYear() as number,
                    true,
                    householdMembers
                        .filter((x) => !householdMembersCovered.includes(x.householdMemberId))
                        .map((x) => x.householdMemberId),
                    GET_PATHWAY_MEDISHARE_PROGRAMS_FOR_USER_ACTION
                )
            );
        }
    }, [
        dispatch,
        excludedHouseholdMembers,
        hasPendingPlanRequests,
        householdMembers,
        householdMembersCovered,
        isOffExchangePlan,
        isOnOrOffExchange,
        pathwayUser?.planId,
        user?.activeDate,
        userId,
    ]);

    return (
        <Skeleton
            count={1}
            height="363px"
            isEnabled={!hasFetchedPlans || !hasLoadedPlanCost || hasPendingPlanRequests}
            sx={{ mb: 3 }}
        >
            <Card className="bg-secondary px-4 py-2">
                <div className="w-100 text-center text-white my-2">{header}</div>
                <PlanCard
                    dataCyPrefix="pathway"
                    handleSelectPlan={handleSelectPlan}
                    householdMembersCovered={householdMembersCovered}
                    individualOnly={individualOnly}
                    isIchraFlow={isIchraFlow}
                    isPrimaryCovered={isPrimaryCovered}
                    pathwayPlanCardHeader={pathwayPlanCardHeader}
                    plan={plan}
                />
            </Card>
        </Skeleton>
    );
};
export default hot(module)(PathwayPlanCard);
