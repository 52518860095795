import { clearAllMediSharePlanDetailsAndRates, clearMarketplacePlans } from 'actions/clear';
import { AppStoreThunkDispatch, IAction } from 'actions/commonAction';
import { getMarketplacePlanRatesForUser } from 'actions/marketplacePlan/getMarketplacePlanRatesForUser';
import { getMediSharePlansAndRatesForUser } from 'actions/medishare/getMediSharePlansAndRatesForUser';
import { ShoppingConfigurationIds } from 'api/generated/enums';
import debounce from 'lodash/debounce';
import some from 'lodash/some';
import without from 'lodash/without';
import { IShoppingState, IStringBoolDictionary } from 'pages/shop/shoppingState';
import { IIdeonProviderWithSource } from 'reducers/ideonProviders';
import { hasFlag } from 'utilities';

const GET_RATES_DELAY_IN_MILLISECONDS = 1000;

const getRates = (
    dispatch: AppStoreThunkDispatch,
    shoppingConfigurationId: ShoppingConfigurationIds | undefined,
    userId: string,
    excludedMembers: string[],
    year: number
) => {
    dispatch(clearAllMediSharePlanDetailsAndRates());
    dispatch(clearMarketplacePlans());
    if (hasFlag(shoppingConfigurationId, ShoppingConfigurationIds.Marketplace)) {
        dispatch(getMarketplacePlanRatesForUser(userId, excludedMembers, year, false));
    }
    if (hasFlag(shoppingConfigurationId, ShoppingConfigurationIds.MediShare)) {
        dispatch(getMediSharePlansAndRatesForUser(userId, year, false, excludedMembers));
    }
    if (hasFlag(shoppingConfigurationId, ShoppingConfigurationIds.OffExchange)) {
        dispatch(getMarketplacePlanRatesForUser(userId, excludedMembers, year, true));
    }
};

const getRatesDebounced = debounce(
    (
        dispatch,
        shoppingConfigurationId: ShoppingConfigurationIds | undefined,
        userId: string,
        excludedMembers: string[],
        year: number
    ) => getRates(dispatch, shoppingConfigurationId, userId, excludedMembers, year),
    GET_RATES_DELAY_IN_MILLISECONDS
);

const getSelectedHouseholdMembers = (householdMembers: IStringBoolDictionary, userId: string) =>
    without(
        Object.keys(householdMembers).filter((x) => householdMembers[x]),
        userId
    );
const getExcludedMembers = (householdMembers: IStringBoolDictionary) =>
    Object.keys(householdMembers).filter((x) => !householdMembers[x]);
export const UPDATE_SHOPPING_MEMBERS_AND_GET_RATES = 'UPDATE_SHOPPING_MEMBERS_AND_GET_RATES';
export const updateShoppingMembersAndGetRates = (
    userId: string,
    members: IStringBoolDictionary,
    year: number,
    shoppingConfigurationId: ShoppingConfigurationIds | undefined,
    shouldDebounce = true
) => (dispatch: AppStoreThunkDispatch) => {
    const excludedMembers = getExcludedMembers(members);
    const selectedHouseholdMembers = getSelectedHouseholdMembers(members, userId);
    const isPrimaryCovered = members[userId];
    dispatch({
        data: { excludedMembers, isPrimaryCovered, members, selectedHouseholdMembers },
        type: UPDATE_SHOPPING_MEMBERS_AND_GET_RATES,
    });
    if (some(members)) {
        const ratesCall = shouldDebounce ? getRatesDebounced : getRates;
        ratesCall(dispatch, shoppingConfigurationId, userId, excludedMembers, year);
    }
};

export const SET_SHOPPING_DISABLED_MEMBERS_ACTION = 'SET_SHOPPING_DISABLED_MEMBERS';
export const setShoppingDisabledMembers = (disabledMembers: string[]) => (
    dispatch: AppStoreThunkDispatch
) => {
    dispatch({ data: { disabledMembers }, type: SET_SHOPPING_DISABLED_MEMBERS_ACTION });
};

export const SET_SHOPPING_SELECTED_YEAR = 'SET_SHOPPING_SELECTED_YEAR';
export const setSelectedYear = (selectedYear: number) => (dispatch: AppStoreThunkDispatch) =>
    dispatch({
        data: { selectedYear },
        type: SET_SHOPPING_SELECTED_YEAR,
    });

export const CLEAR_SHOPPING_STATE_ACTION = 'CLEAR_SHOPPING_STATE';
export const clearShoppingState = () => ({
    type: CLEAR_SHOPPING_STATE_ACTION,
});

export const SET_SHOPPING_SELECTED_PROVIDERS_ACTION = 'SET_SHOPPING_SELECTED_PROVIDERS';
export const setSelectedProviders = (selectedProviders: IIdeonProviderWithSource[]) => (
    dispatch: AppStoreThunkDispatch
) =>
    dispatch({
        data: { selectedProviders },
        type: SET_SHOPPING_SELECTED_PROVIDERS_ACTION,
    } as IAction<IShoppingState>);

export const clearSelectedProviders = () => async (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        type: SET_SHOPPING_SELECTED_PROVIDERS_ACTION.clear,
    });
};

export const SET_HAS_ACKNOWLEDGED_PROVIDER_DISCLAIMER_ACTION =
    'SET_HAS_ACKNOWLEDGED_PROVIDER_DISCLAIMER';
export const setShowProviderDisclaimerModal = () => async (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        type: SET_HAS_ACKNOWLEDGED_PROVIDER_DISCLAIMER_ACTION,
    });
};
