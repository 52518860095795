import Autorenew from '@mui/icons-material/Autorenew';
import GppMaybeIcon from '@mui/icons-material/GppMaybeOutlined';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { BasicPathwayUserDto, IPathwayBlueprint } from 'api/generated/models';
import Select from 'components/Select';
import Skeleton from 'components/Skeleton';
import SpinAnimation from 'components/SpinAnimation';
import TextField from 'components/TextField';
import Tooltip from 'components/Tooltip';
import groupBy from 'lodash/groupBy';
import { PathwayFilterContext } from 'pages/pathwayBlueprint/PathwayBlueprintPage';
import { StaleIcon } from 'pages/pathwayBlueprint/PathwayTableCells';
import React, { useContext } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { arrayHasValue, hasValue } from 'utilities';
import { formatDateAtTime } from 'utilities/format';
import { onChange } from 'utilities/forms';

export const STALE_ICHRA_CLASS_TEXT =
    'There is at least one ICHRA Class with a Top-Line Reimbursement Strategy that has not been applied. Please Edit the ICHRA Class and apply the strategy.';

const CAPTION_LINE_HEIGHT = '24px';

type IPathwayBlueprintNameHeaderProps = {
    appendToName?: string;
    isLoading: boolean;
    statesAvailable?: { name: string }[];
};

const CalculatingIcon = () => (
    <SpinAnimation>
        <Tooltip title="Modifying the pathway blueprint is disabled while Wage+ amounts are calculating.">
            <Autorenew sx={{ color: (theme) => theme.palette.yellow.main }} />
        </Tooltip>
    </SpinAnimation>
);

const getIchraClassStaleIcon = (pathwayBlueprint: IPathwayBlueprint) => {
    const staleIchraClasses = pathwayBlueprint.ichraClasses?.filter((ic) => ic.isStale);
    if (!arrayHasValue(staleIchraClasses)) {
        return <React.Fragment />;
    }
    const title = (
        <span>
            <div className="text-left">{STALE_ICHRA_CLASS_TEXT}</div>
            <div className="text-left">
                <ul>
                    {staleIchraClasses.map((sic) => (
                        <li key={sic.globalId}>{sic.name}</li>
                    ))}
                </ul>
            </div>
        </span>
    );
    return (
        <Tooltip title={title}>
            <Autorenew sx={{ color: (theme) => theme.palette.orange.main }} />
        </Tooltip>
    );
};

const getHeaderCalculationIcon = ({
    isCalculating,
    isStale,
    lastBulkCalculatedDate,
}: IPathwayBlueprint) => {
    if (isCalculating) {
        return (
            <Stack direction="row">
                <CalculatingIcon />
                <Typography
                    color={(theme) => theme.palette.muted.main}
                    ml={0.5}
                    sx={{ lineHeight: CAPTION_LINE_HEIGHT }}
                    variant="caption"
                >
                    Calculating Wage+ amounts. An email will be sent to the member that started the
                    calculation.
                </Typography>
            </Stack>
        );
    } else {
        return (
            <Stack direction="row">
                <StaleIcon
                    isStale={!!isStale}
                    lastCalculatedDate={lastBulkCalculatedDate}
                    lastCalculatedFieldName="Last Bulk Calculation Date"
                />
                <Typography
                    color={(theme) => theme.palette.muted.main}
                    ml={0.5}
                    sx={{ lineHeight: CAPTION_LINE_HEIGHT }}
                    variant="caption"
                >
                    Last Bulk Calculation Date - {formatDateAtTime(lastBulkCalculatedDate, 'Never')}
                </Typography>
            </Stack>
        );
    }
};

const getHeaderIconSection = ({
    label,
    pathwayUsers,
}: {
    label: string;
    pathwayUsers: BasicPathwayUserDto[] | undefined;
}) => {
    if (!pathwayUsers || pathwayUsers?.length === 0) {
        return <React.Fragment />;
    }
    const groupedByPathway = groupBy(pathwayUsers, (x) => x.pathwayName);

    return (
        <React.Fragment>
            <div className="text-left">{label}</div>
            {Object.keys(groupedByPathway).map((key, index) => (
                <ul className="text-left" key={index}>
                    <li>{key}</li>
                    {groupedByPathway[key]?.map((user, _index) => (
                        <ul className="text-left" key={_index}>
                            <li>
                                {user.userFirstName} {user.userLastName}
                            </li>
                        </ul>
                    ))}
                </ul>
            ))}
        </React.Fragment>
    );
};

const HeaderIcon = ({
    pathwayUsersWithLostEligibilities,
    pathwayUsersWithUnaffordableIchra,
}: {
    pathwayUsersWithLostEligibilities: BasicPathwayUserDto[] | undefined;
    pathwayUsersWithUnaffordableIchra: BasicPathwayUserDto[] | undefined;
}) => {
    if (
        (!pathwayUsersWithLostEligibilities || pathwayUsersWithLostEligibilities?.length === 0) &&
        (!pathwayUsersWithUnaffordableIchra || pathwayUsersWithUnaffordableIchra?.length === 0)
    ) {
        return <React.Fragment />;
    }
    return (
        <Tooltip
            title={
                <span>
                    {getHeaderIconSection({
                        label:
                            'The following members have lost Medicaid, CHIP, or CSR eligibility due to their Wage+:',
                        pathwayUsers: pathwayUsersWithLostEligibilities,
                    })}
                    {getHeaderIconSection({
                        label: 'This ICHRA strategy is unaffordable for the following members:',
                        pathwayUsers: pathwayUsersWithUnaffordableIchra,
                    })}
                </span>
            }
        >
            <GppMaybeIcon color="warning" />
        </Tooltip>
    );
};

const getHeaderIcon = ({
    pathwayUsersWithLostEligibilities,
    pathwayUsersWithUnaffordableIchra,
}: IPathwayBlueprint) => (
    <HeaderIcon
        pathwayUsersWithLostEligibilities={pathwayUsersWithLostEligibilities}
        pathwayUsersWithUnaffordableIchra={pathwayUsersWithUnaffordableIchra}
    />
);

const PathwayBlueprintNameHeader: React.FC<IPathwayBlueprintNameHeaderProps> = ({
    children,
    appendToName,
    isLoading,
    statesAvailable = [],
}) => {
    const { pathwayBlueprint } = useSelector((state: AppStore) => ({
        pathwayBlueprint: state.pathwayBlueprint,
    }));
    const { filteredState, search, setFilteredState, setSearch } = useContext(PathwayFilterContext);
    const history = useHistory();
    const append = !hasValue(appendToName) ? '' : ` ${appendToName}`;
    const isCostComparisonPage = history.location.pathname.includes('cost-comparison');

    return (
        <Stack>
            <Stack alignItems="center" direction="row">
                <span className="mr-1">
                    <Skeleton count={1} isEnabled={isLoading} width="200px">
                        {pathwayBlueprint.name} - {pathwayBlueprint.year}
                        {append}
                    </Skeleton>
                </span>
                {children}
                {getHeaderIcon(pathwayBlueprint)}
                {getIchraClassStaleIcon(pathwayBlueprint)}
                {getHeaderCalculationIcon(pathwayBlueprint)}
            </Stack>
            {pathwayBlueprint.isProjection && (
                <Typography
                    color={(theme) => theme.palette.error.main}
                    sx={{ fontStyle: 'italic', lineHeight: CAPTION_LINE_HEIGHT }}
                    textAlign="left"
                    variant="caption"
                >
                    Projection - Using {pathwayBlueprint.year - 1} Marketplace rates
                </Typography>
            )}
            {!isCostComparisonPage && (
                <Stack direction="row" gap={2}>
                    <TextField
                        className="mb-sm-0 mb-2 mt-1"
                        data-cy="search"
                        name="search"
                        onChange={onChange(
                            (setSearch as unknown) as React.Dispatch<
                                React.SetStateAction<string | undefined>
                            >
                        )}
                        placeholder="Search"
                        size="small"
                        sx={{ width: 200 }}
                        type="search"
                        value={search}
                    />
                    {arrayHasValue(statesAvailable) && (
                        <Select
                            data-cy="states"
                            defaultText="Filter by state"
                            defaultValue=""
                            items={statesAvailable}
                            label="State"
                            multiline
                            name="states"
                            onChange={onChange(
                                (setFilteredState as unknown) as React.Dispatch<
                                    React.SetStateAction<string | undefined>
                                >
                            )}
                            optionText="name"
                            optionValue="name"
                            size="small"
                            sx={{ marginTop: 0.8, maxWidth: 100 }}
                            value={filteredState}
                        />
                    )}
                </Stack>
            )}
        </Stack>
    );
};

export default hot(module)(PathwayBlueprintNameHeader);
