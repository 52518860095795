import { AppStoreThunkDispatch } from 'actions/commonAction';
import { addPathway } from 'actions/pathwayBlueprint/addPathway';
import { editPathway } from 'actions/pathwayBlueprint/editPathway';
import { PathwayInputTypes, PathwayTypes } from 'api/generated/enums';
import { IPathway, IPathwayInput, PathwayInput } from 'api/generated/models';
import { IPathwayModalState, schema } from 'pages/pathwayBlueprint/pathwayModalState';
import { AppStore } from 'reducers/appReducer';
import { IValidateData, formatErrors, validate } from 'utilities/forms';
import { hasValue } from 'utilities/index';
import {
    getIsPathwayOfType,
    isCappedCostIchraPathwayType,
    isCappedWageUpPathwayType,
    isEmployeeCappedPathwayType,
    isExchangeBenchmarkPathwayType,
    isHealthcareSharingPathwayType,
    requiresCoverageLevelInputs,
    requiresPlan,
} from 'utilities/pathways';
import { ValidationError } from 'yup';

export const SET_PATHWAY_MODAL_PROPS_ACTION = 'SET_PATHWAY_MODAL_PROPS';
export const SET_PATHWAY_INPUTS_ACTION = 'SET_PATHWAY_INPUTS';
export const CLEAR_PATHWAY_INPUTS_ACTION = 'CLEAR_PATHWAY_INPUTS';

export const setPathwayModalProps = (data: {
    isModalVisible: boolean;
    selectedItem: IPathway | undefined;
}) => (dispatch: AppStoreThunkDispatch) => dispatch({ data, type: SET_PATHWAY_MODAL_PROPS_ACTION });

export const setPathwayInputs = (data: Partial<IPathwayModalState['pathwayInputs']>) => (
    dispatch: AppStoreThunkDispatch
) => dispatch({ data, type: SET_PATHWAY_INPUTS_ACTION });

export const clearPathwayInputs = () => (dispatch: AppStoreThunkDispatch) =>
    dispatch({ type: CLEAR_PATHWAY_INPUTS_ACTION });

type IMainPathwayModalState = {
    additionalDependentKicker: string;
    ageBandSize: string;
    ahpOption: string;
    automaticApprovalCap: string;
    capType: string;
    chipEstimate: string;
    dependentKickerThreshold: string;
    displayNameOverride: string;
    ec: string;
    ecReimbursementRatio: string;
    ee: string;
    eeReimbursementRatio: string;
    ef: string;
    efReimbursementRatio: string;
    employerCoversEeOnly: string;
    es: string;
    esReimbursementRatio: string;
    extraBenefitText: string;
    ichraClassId?: string;
    medicaidEstimate: string;
    pathwayName: string;
    pathwayType: string;
    perMemberMedicareCostEstimate: string;
    sixtyFourEc: string;
    sixtyFourEe: string;
    sixtyFourEf: string;
    sixtyFourEs: string;
    startingWageUp: string;
    useRestrategizedRenewalFlow: string;
    wageUpCap: string;
    waivedContribution: string;
};

export const savePathway = (
    pathwayBlueprintId: string,
    pathway: IPathway | undefined,
    mainPathwayModalState: IMainPathwayModalState
) => async (dispatch: AppStoreThunkDispatch, getState: () => AppStore) => {
    const { pathwayModalState } = getState();
    const {
        additionalDependentKicker,
        ageBandSize,
        ahpOption,
        automaticApprovalCap,
        capType,
        chipEstimate,
        dependentKickerThreshold,
        displayNameOverride,
        ec,
        ecReimbursementRatio,
        ee,
        eeReimbursementRatio,
        ef,
        efReimbursementRatio,
        employerCoversEeOnly,
        es,
        esReimbursementRatio,
        extraBenefitText,
        ichraClassId,
        medicaidEstimate,
        pathwayName,
        pathwayType,
        perMemberMedicareCostEstimate,
        sixtyFourEc,
        sixtyFourEe,
        sixtyFourEf,
        sixtyFourEs,
        startingWageUp,
        useRestrategizedRenewalFlow,
        wageUpCap,
        waivedContribution,
    } = mainPathwayModalState;
    const { county, ichraBenchmarkType, marketplaceCarrier, marketplacePlanId, zipCode } =
        pathwayModalState.pathwayInputs ?? {};
    const combinedPathwayModalState = {
        ...mainPathwayModalState,
        ...pathwayModalState.pathwayInputs,
    };

    try {
        await validate(schema, combinedPathwayModalState as IValidateData<typeof schema>);
        dispatch(setPathwayInputs({ errors: null }));
    } catch (errors) {
        dispatch(setPathwayInputs({ errors: formatErrors(errors as ValidationError) }));
        return false;
    }

    const inputs = [];
    const isSpouseOrParentPathwayType = getIsPathwayOfType(
        pathwayType,
        PathwayTypes.Spouse,
        PathwayTypes.Parent
    );
    const isCappedWageUpPathway = isCappedWageUpPathwayType(pathwayType);
    const isEmployeeCappedType = isEmployeeCappedPathwayType(pathwayType);
    const isExchangeBenchmarkType = isExchangeBenchmarkPathwayType(pathwayType);
    const isSharingType = isHealthcareSharingPathwayType(pathwayType);
    const isWaivedPathwayType = getIsPathwayOfType(pathwayType, PathwayTypes.Waived);
    const isMedicaidPathwayType = getIsPathwayOfType(pathwayType, PathwayTypes.Medicaid);
    const isMedicarePathwayType = getIsPathwayOfType(
        pathwayType,
        PathwayTypes.Medicare,
        PathwayTypes.IchraMedicare
    );
    const isAgeBandedIchraPathway = getIsPathwayOfType(pathwayType, PathwayTypes.IchraAgeBanded);
    const isCappedCostIchraPathway = isCappedCostIchraPathwayType(pathwayType);
    const includeCoverageLevelInputs = requiresCoverageLevelInputs(pathwayType);
    const isPlanRequired = requiresPlan(pathwayType);

    if (isCappedWageUpPathway) {
        inputs.push(
            { pathwayInputTypeId: PathwayInputTypes.ChipEstimate, value: chipEstimate },
            {
                pathwayInputTypeId: PathwayInputTypes.PerMemberMedicareCostEstimate,
                value: perMemberMedicareCostEstimate,
            }
        );
    }
    if (isCappedWageUpPathway && isEmployeeCappedType) {
        inputs.push({ pathwayInputTypeId: PathwayInputTypes.WageUpCap, value: wageUpCap });
    }
    if (includeCoverageLevelInputs) {
        inputs.push(
            { pathwayInputTypeId: PathwayInputTypes.CoverageLevelEE, value: ee },
            { pathwayInputTypeId: PathwayInputTypes.CoverageLevelES, value: es },
            { pathwayInputTypeId: PathwayInputTypes.CoverageLevelEC, value: ec },
            { pathwayInputTypeId: PathwayInputTypes.CoverageLevelEF, value: ef }
        );
    }
    if (isExchangeBenchmarkType) {
        inputs.push(
            { pathwayInputTypeId: PathwayInputTypes.Zipcode, value: zipCode },
            { pathwayInputTypeId: PathwayInputTypes.County, value: county },
            {
                pathwayInputTypeId: PathwayInputTypes.MarketplaceCarrier,
                value: marketplaceCarrier,
            },
            {
                pathwayInputTypeId: PathwayInputTypes.MarketplacePlanId,
                value: marketplacePlanId,
            }
        );
    }
    if (isSharingType) {
        inputs.push({
            pathwayInputTypeId: PathwayInputTypes.MediShareBenchmarkProgram,
            value: ahpOption,
        });
    }
    if (isSpouseOrParentPathwayType) {
        inputs.push(
            { pathwayInputTypeId: PathwayInputTypes.StartingWageUp, value: startingWageUp },
            {
                pathwayInputTypeId: PathwayInputTypes.AutomaticApprovalCap,
                value: automaticApprovalCap,
            }
        );
    }
    if (isWaivedPathwayType) {
        inputs.push({
            pathwayInputTypeId: PathwayInputTypes.WaivedContribution,
            value: waivedContribution,
        });
    }
    if (isMedicaidPathwayType) {
        inputs.push({
            pathwayInputTypeId: PathwayInputTypes.MedicaidEstimate,
            value: medicaidEstimate,
        });
    }
    if (isMedicarePathwayType) {
        inputs.push({
            pathwayInputTypeId: PathwayInputTypes.PerMemberMedicareCostEstimate,
            value: perMemberMedicareCostEstimate,
        });
    }
    if (isCappedCostIchraPathway) {
        inputs.push(
            {
                pathwayInputTypeId: PathwayInputTypes.IchraBenchmarkType,
                value: ichraBenchmarkType,
            },
            {
                pathwayInputTypeId: PathwayInputTypes.PerMemberMedicareCostEstimate,
                value: perMemberMedicareCostEstimate,
            }
        );

        inputs.push({
            pathwayInputTypeId: PathwayInputTypes.EmployerCoversEeOnly,
            value: employerCoversEeOnly,
        });
    }
    if (isAgeBandedIchraPathway) {
        inputs.push(
            {
                pathwayInputTypeId: PathwayInputTypes.IchraBenchmarkType,
                value: ichraBenchmarkType,
            },
            {
                pathwayInputTypeId: PathwayInputTypes.AdditionalDependentKicker,
                value: additionalDependentKicker,
            },
            {
                pathwayInputTypeId: PathwayInputTypes.DependentKickerThreshold,
                value: dependentKickerThreshold,
            },
            {
                pathwayInputTypeId: PathwayInputTypes.AgeBandSize,
                value: ageBandSize,
            },
            {
                pathwayInputTypeId: PathwayInputTypes.SixtyFourYearOldEE,
                value: sixtyFourEe,
            },
            {
                pathwayInputTypeId: PathwayInputTypes.SixtyFourYearOldES,
                value: sixtyFourEs,
            },
            {
                pathwayInputTypeId: PathwayInputTypes.SixtyFourYearOldEC,
                value: sixtyFourEc,
            },
            {
                pathwayInputTypeId: PathwayInputTypes.SixtyFourYearOldEF,
                value: sixtyFourEf,
            },
            {
                pathwayInputTypeId: PathwayInputTypes.PerMemberMedicareCostEstimate,
                value: perMemberMedicareCostEstimate,
            },
            {
                pathwayInputTypeId: PathwayInputTypes.EeReimbursementRatio,
                value: eeReimbursementRatio,
            },
            {
                pathwayInputTypeId: PathwayInputTypes.EsReimbursementRatio,
                value: esReimbursementRatio,
            },
            {
                pathwayInputTypeId: PathwayInputTypes.EcReimbursementRatio,
                value: ecReimbursementRatio,
            },
            {
                pathwayInputTypeId: PathwayInputTypes.EfReimbursementRatio,
                value: efReimbursementRatio,
            }
        );
    }
    if (isPlanRequired) {
        inputs.push(
            {
                pathwayInputTypeId: PathwayInputTypes.UseRestrategizedRenewalFlow,
                value: useRestrategizedRenewalFlow,
            },
            {
                pathwayInputTypeId: PathwayInputTypes.ExtraBenefitText,
                value: extraBenefitText,
            },
            {
                pathwayInputTypeId: PathwayInputTypes.DisplayNameOverride,
                value: displayNameOverride,
            }
        );
        if (isEmployeeCappedType) {
            inputs.push({ pathwayInputTypeId: PathwayInputTypes.CapType, value: capType });
        }
    }

    const pathwayInputs = inputs.map((x) => new PathwayInput(x as IPathwayInput));
    const newPathway = {
        pathwayBlueprintId,
        pathwayInputs,
        ichraClassId: hasValue(ichraClassId) ? Number(ichraClassId) : null,
        name: pathwayName,
        pathwayTypeId: Number(pathwayType),
    };
    if (pathway?.id) {
        await dispatch(
            editPathway({
                ...newPathway,
                id: pathway.id,
            } as IPathway)
        );
    } else {
        await dispatch(addPathway(newPathway as IPathway));
    }
    return true;
};
