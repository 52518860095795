import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Stack,
} from '@mui/material';
import {
    PROCESS_COVERAGE_LEVEL_FOR_REIMBURSEMENT_ACTION,
    processCoverageLevelForReimbursement,
} from 'actions/householdMember/processCoverageLevelForReimbursement';
import { applyShoppingCoverageChanges } from 'actions/householdShoppingCoverage/applyShoppingCoverageChanges';
import { setInitialReimbursement } from 'actions/householdShoppingCoverage/setInitialReimbursement';
import { HouseholdEligibilityStatus } from 'api/generated/enums';
import { IHouseholdMemberDto, IMemberEligibility } from 'api/generated/models';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { ShoppingContext } from 'pages/shop/shopPageUtilities';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { IHouseholdMemberWithAge } from 'reducers/householdMembers';
import { hasApiActivity, hasCompletedRequest } from 'selectors/activity';
import { formatCurrency } from 'utilities/format';

export type ChangeHouseholdCoverageModalProps = {
    householdEligibility: IMemberEligibility[] | undefined;
    householdMembers: IHouseholdMemberWithAge[];
    isIchraFlow: boolean | undefined;
    isPrimaryMedicare: boolean;
    isPrimaryNeedingCoverage: boolean;
    onClose: () => void;
    selectedHhms: string[];
};

const ChangeHouseholdCoverageModal = ({
    householdEligibility,
    householdMembers,
    isIchraFlow,
    isPrimaryMedicare,
    isPrimaryNeedingCoverage,
    onClose,
    selectedHhms,
}: ChangeHouseholdCoverageModalProps) => {
    const dispatch = useThunkDispatch();
    const { activeWageUp, user } = useUserProps();
    const { setHasFetchedPlans } = useContext(ShoppingContext);
    const { householdShoppingCoverage, isLoading } = useSelector((state: AppStore) => ({
        hasFinishedRequest: hasCompletedRequest(
            state,
            PROCESS_COVERAGE_LEVEL_FOR_REIMBURSEMENT_ACTION
        ),
        householdShoppingCoverage: state.householdShoppingCoverage,
        isLoading: hasApiActivity(state, PROCESS_COVERAGE_LEVEL_FOR_REIMBURSEMENT_ACTION),
    }));
    const [hhmsWhoNeedCoverage, setHhmsWhoNeedCoverage] = useState<string[]>(selectedHhms);
    const [hhmIdsWithMedicare, setHhmIdsWithMedicare] = useState<string[]>();
    const year = user?.activeDate?.getYear() ?? 0;
    const reimbursementAmount = formatCurrency(householdShoppingCoverage?.reimbursement, {
        preserveDecimal: true,
    });

    const handleRecalc = async (isChecked: boolean, householdMemberId: string) => {
        dispatch(
            processCoverageLevelForReimbursement(householdMemberId, year, isChecked, !isIchraFlow)
        );
    };

    const handleSelect = (memberId: string) => {
        let hhmsToAdd = [...(hhmsWhoNeedCoverage ?? [])];
        let isChecked = true;

        if (hhmsToAdd.includes(memberId)) {
            hhmsToAdd = hhmsToAdd.filter((hhm) => hhm !== memberId);
            isChecked = false;
        } else {
            hhmsToAdd.push(memberId);
        }

        handleRecalc(isChecked, memberId);
        setHhmsWhoNeedCoverage(hhmsToAdd);
    };

    const handlePrimaryMemberTooltip = () => {
        if (isPrimaryMedicare) {
            return `${user?.firstName} may be eligible for Medicare, so you cannot shop for them here. Reach out to care@remodelhealth.com if this member needs to be changed.`;
        }
        const adjustment = isPrimaryNeedingCoverage ? '' : 'not ';
        return `${user?.firstName} is marked as ${adjustment}needing coverage. Reach out to care@remodelhealth.com if this member needs to be changed.`;
    };

    const handleToolTip = (member: IHouseholdMemberDto) => {
        const needsCoverageAdjust1 = member.needsCoverage ? '' : 'not ';
        const needsCoverageAdjust2 = member.needsCoverage ? 'but' : 'and';
        return hhmIdsWithMedicare?.includes(member.householdMemberId) ? (
            `${member.firstName} is marked as ${needsCoverageAdjust1}needing coverage ${needsCoverageAdjust2} may be eligible for Medicare, so you cannot shop for them here. Reach out to care@remodelhealth.com if this member needs to be changed.`
        ) : (
            <span>
                Age on Effective Date: {member.dateOfBirth.getAge(user?.activeDate)}
                <br />
                Gender: {member.gender}
            </span>
        );
    };

    useEffect(() => {
        dispatch(setInitialReimbursement(activeWageUp?.reimbursement ?? 0));
    }, [activeWageUp?.reimbursement, dispatch]);

    useEffect(() => {
        const memberIdsToAdd = householdEligibility
            ?.filter((he) => he.eligibilityStatus === HouseholdEligibilityStatus.Medicare)
            .map((hem) => hem.memberId);

        setHhmIdsWithMedicare(memberIdsToAdd);
    }, [householdEligibility]);

    const applyChangesAndClose = useCallback(() => {
        dispatch(applyShoppingCoverageChanges(householdShoppingCoverage.householdMembersChanged));
        onClose();
    }, [dispatch, householdShoppingCoverage.householdMembersChanged, onClose]);

    useEffect(
        () => () => {
            setHasFetchedPlans?.(false);
        },
        [setHasFetchedPlans]
    );

    return (
        <Dialog fullWidth maxWidth="sm" open>
            <DialogTitle>
                <Typography textAlign="center" variant="h3">
                    Change Who Needs Coverage
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container direction="column" gap={2}>
                    <Typography variant="body1">
                        Check or uncheck household members to change who you are seeking coverage
                        for. This will update who you are shopping for and may change your
                        reimbursement amount.
                    </Typography>
                    {!isIchraFlow && (
                        <Typography variant="body1">
                            Making changes will clear your current plan selections.
                        </Typography>
                    )}
                    <Grid container direction="row" gap={2} item marginX="auto">
                        <Tooltip title={handlePrimaryMemberTooltip()}>
                            <Checkbox
                                checked={isPrimaryNeedingCoverage && !isPrimaryMedicare}
                                disabled
                                label={user?.firstName as string}
                                name="userName"
                            />
                        </Tooltip>
                        {householdMembers.map((member) => (
                            <Tooltip key={member.firstName} title={handleToolTip(member)}>
                                <Checkbox
                                    checked={hhmsWhoNeedCoverage?.includes(
                                        member.householdMemberId
                                    )}
                                    disabled={
                                        !!hhmIdsWithMedicare?.includes(member.householdMemberId) ||
                                        isLoading
                                    }
                                    label={member?.firstName as string}
                                    name="memberName"
                                    onChange={() => handleSelect(member.householdMemberId)}
                                />
                            </Tooltip>
                        ))}
                    </Grid>
                    <Divider />
                    <Typography alignSelf="center" variant="h4">
                        Reimbursement Amount: {reimbursementAmount}
                    </Typography>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Stack direction="column" gap={3} width="100%">
                    <Grid container justifyContent="center">
                        <Button
                            isLoading={isLoading}
                            onClick={applyChangesAndClose}
                            variant="contained"
                        >
                            Continue
                        </Button>
                    </Grid>
                    <Typography alignSelf="center" marginBottom={1} variant="body2">
                        To add someone to your household reach out to{' '}
                        <a href="care@remodelhealth.com">care@remodelhealth.com</a>
                    </Typography>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default hot(module)(ChangeHouseholdCoverageModal);
