import React, {ReactNode} from 'react';
import {hot} from 'react-hot-loader';

const ClassCodeName = ({children}: { children: ReactNode }) => (
    <p className="my-0 text-right">{children}</p>
);

const AdpBenefitsEligibilityClassCodeInfo = ({
                                                 data,
                                             }: {
    data: string[];
}) => {
    if (!data || data.length === 0) {
        return <React.Fragment/>;
    }
    return (
        <React.Fragment>
            {data.map((classCode) => (
                <ClassCodeName key={`class-code-${classCode}`}>{classCode}</ClassCodeName>
            ))}
        </React.Fragment>
    );
};

export default hot(module)(AdpBenefitsEligibilityClassCodeInfo);
