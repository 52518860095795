import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AgeBandSizes, TopLineReimbursementStrategies } from 'api/generated/enums';
import { IIchraClass } from 'api/generated/models';
import CurrencyTextField from 'components/CurrencyTextField';
import Form from 'components/Form';
import NumberTextField from 'components/NumberTextField';
import PercentTextField from 'components/PercentTextField';
import Select from 'components/Select';
import TextField from 'components/TextField';
import AgeBandCoverageLevelInputs from 'components/pathwayModal/AgeBandCoverageLevelInputs';
import AgeBandsTable from 'components/pathwayModal/AgeBandsTable';
import MarketplacePathwayInputs from 'pages/pathwayBlueprint/MarketplacePathwayInputs';
import { getAdditionalDependentKickerHelperText } from 'pages/pathwayBlueprint/PathwayModal';
import React, { Dispatch, SetStateAction } from 'react';
import { hot } from 'react-hot-loader';
import { IFormErrors } from 'utilities/forms';
import { enumToNameValueArray, hasValue } from 'utilities/index';
import { object, string } from 'yup';

const ICHRA_RATIO_MAX = 3;
const ICHRA_DEPENDENT_KICKER_THRESHOLD_MAX = 10;
const ICHRA_CLASS_NAME_MAX = 255;
const ICHRA_AGE_BAND_FIVE = 5;
const ICHRA_AGE_BAND_TEN = 10;
const ageBandSizesArray = enumToNameValueArray(AgeBandSizes, {
    nameMap: { Five: '5-Years', One: '1-Year', Ten: '10-Years' },
});
const topLineReimbursementStrategyArray = enumToNameValueArray(TopLineReimbursementStrategies, {
    nameMap: {
        CoverageLevelPercentage: 'Coverage Level Percentage',
        Custom: 'Custom',
        SplitPrimaryHouseholdPercentage: 'Split Primary Household Percentage',
    },
});
const getAgeBandSize = (ageBandId: AgeBandSizes) => {
    switch (ageBandId) {
        case AgeBandSizes.One:
            return 1;
        case AgeBandSizes.Five:
            return ICHRA_AGE_BAND_FIVE;
        case AgeBandSizes.Ten:
            return ICHRA_AGE_BAND_TEN;
        default:
            return 0;
    }
};

const isCoverageLevelPercentage = [
    'topLineReimbursementStrategyId',
    {
        is: (x: number | string) =>
            hasValue(x) && Number(x) === TopLineReimbursementStrategies.CoverageLevelPercentage,
        then: string().required(),
    },
] as const;
const isSplitPrimaryHouseholdPercentage = [
    'topLineReimbursementStrategyId',
    {
        is: (x: number | string) =>
            hasValue(x) &&
            Number(x) === TopLineReimbursementStrategies.SplitPrimaryHouseholdPercentage,
        then: string().required(),
    },
] as const;
const isPercentageCostStrategy = [
    'topLineReimbursementStrategyId',
    {
        is: (x: number | string) =>
            hasValue(x) &&
            [
                TopLineReimbursementStrategies.CoverageLevelPercentage,
                TopLineReimbursementStrategies.SplitPrimaryHouseholdPercentage,
            ].includes(Number(x)),
        then: string().required(),
    },
] as const;

const handlePercentChange = (value: string | undefined) =>
    hasValue(value) ? Number(value) : undefined;

export const ichraClassSchema = (needsToApplyStrategy: boolean) =>
    object({
        additionalDependentKicker: string()
            .min(0)
            .label('Additional Dependent Kicker'),
        ageBandSizeId: string()
            .required()
            .label('Age Band Size'),
        county: string()
            .trim()
            .when(...isPercentageCostStrategy)
            .label('County'),
        dependentKickerCoveragePercent: string()
            .min(0)
            .label('Dependent Kicker Coverage Percent'),
        dependentKickerThreshold: string()
            .max(ICHRA_DEPENDENT_KICKER_THRESHOLD_MAX)
            .min(0)
            .required()
            .label('Dependent Kicker Threshold'),
        ec64: string()
            .isRequiredWhen(!needsToApplyStrategy)
            .min(1)
            .label('64+ EC'),
        ecCoveragePercent: string()
            .when(...isCoverageLevelPercentage)
            .min(0)
            .label('EC Coverage Percentage'),
        ecRatio: string()
            .required()
            .min(1)
            .max(ICHRA_RATIO_MAX)
            .label('EC Ratio'),
        ee64: string()
            .isRequiredWhen(!needsToApplyStrategy)
            .min(1)
            .label('64+ EE'),
        eeCoveragePercent: string()
            .when(...isPercentageCostStrategy)
            .min(0)
            .label('EE Coverage Percentage'),
        eeRatio: string()
            .required()
            .min(1)
            .max(ICHRA_RATIO_MAX)
            .label('EE Ratio'),
        ef64: string()
            .isRequiredWhen(!needsToApplyStrategy)
            .min(1)
            .label('64+ EF'),
        efCoveragePercent: string()
            .when(...isCoverageLevelPercentage)
            .min(0)
            .label('EF Coverage Percentage'),
        efRatio: string()
            .required()
            .min(1)
            .max(ICHRA_RATIO_MAX)
            .label('EF Ratio'),
        es64: string()
            .isRequiredWhen(!needsToApplyStrategy)
            .min(1)
            .label('64+ ES'),
        esCoveragePercent: string()
            .when(...isCoverageLevelPercentage)
            .min(0)
            .label('ES Coverage Percentage'),
        esRatio: string()
            .required()
            .min(1)
            .max(ICHRA_RATIO_MAX)
            .label('ES Ratio'),
        ichraBenchmarkType: string()
            .when(...isPercentageCostStrategy)
            .label('ICHRA Benchmark Type'),
        marketplaceCarrier: string()
            .when(...isPercentageCostStrategy)
            .label('Benchmark Carrier'),
        marketplacePlanId: string()
            .when(...isPercentageCostStrategy)
            .label('Benchmark Plan'),
        name: string()
            .trim()
            .required()
            .max(ICHRA_CLASS_NAME_MAX)
            .label('Class Name'),
        otherHouseholdMemberCoveragePercentage: string()
            .when(...isSplitPrimaryHouseholdPercentage)
            .min(0)
            .label('Other Coverage Household Percentage'),
        topLineReimbursementStrategyId: string()
            .required()
            .label('Top-Line Reimbursement Strategy'),
        zipCode: string()
            .trim()
            .when(...isPercentageCostStrategy)
            .label('Zip Code'),
    });

type IIchraClassForm = {
    errors: IFormErrors<ReturnType<typeof ichraClassSchema>>;
    ichraClass: IIchraClass;
    setAgeBandValue: (name: string, value: number) => void;
    setIchraClass: Dispatch<SetStateAction<IIchraClass>>;
};

const IchraClassForm = ({
    errors,
    ichraClass,
    setAgeBandValue,
    setIchraClass,
}: IIchraClassForm) => {
    const isNotCustomClass =
        ichraClass.topLineReimbursementStrategyId !== TopLineReimbursementStrategies.Custom;
    return (
        <Form>
            <Grid columnSpacing={2} container item marginTop={2} rowGap={2} xs={12}>
                <Grid item xs={12}>
                    <TextField
                        errors={errors?.name}
                        label="Class Name"
                        name="className"
                        onChange={(e) => setIchraClass({ ...ichraClass, name: e.target.value })}
                        value={ichraClass.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Select
                        errors={errors?.topLineReimbursementStrategyId}
                        items={topLineReimbursementStrategyArray}
                        label="Top-Line Reimbursement Strategy"
                        name="topLineReimbursementStrategy"
                        onChange={(e) =>
                            setIchraClass({
                                ...ichraClass,
                                topLineReimbursementStrategyId: Number(e.target.value),
                            })
                        }
                        optionText="name"
                        optionValue="value"
                        value={ichraClass.topLineReimbursementStrategyId}
                    />
                </Grid>
                {[
                    TopLineReimbursementStrategies.CoverageLevelPercentage,
                    TopLineReimbursementStrategies.SplitPrimaryHouseholdPercentage,
                ].includes(ichraClass.topLineReimbursementStrategyId) && (
                    <Grid container gap={2} item xs={12}>
                        <MarketplacePathwayInputs
                            ichraClass={ichraClass}
                            isAgeBandedIchraPathway={false}
                            isCappedIchraPathway={false}
                        />
                        <PercentTextField
                            data-cy="dependentKickerCoveragePercent"
                            errors={errors?.dependentKickerCoveragePercent}
                            helperText="When the additional dependent kicker amount is recalculated, it will be set to this percentage of an 18 year-old's cost for the benchmark plan."
                            isOptional
                            label="Dependent Kicker Coverage Percentage"
                            name="dependentKickerCoveragePercent"
                            onChange={(e) =>
                                setIchraClass({
                                    ...ichraClass,
                                    dependentKickerCoveragePercentage: handlePercentChange(
                                        e.target.value
                                    ),
                                })
                            }
                            placeholder="Enter Dependent Kicker Coverage Percentage"
                            value={ichraClass.dependentKickerCoveragePercentage}
                        />
                        <Stack direction="row" spacing={2} width="100%">
                            <PercentTextField
                                data-cy="eeCoveragePercent"
                                errors={errors?.eeCoveragePercent}
                                label="EE Coverage Percentage"
                                name="eeCoveragePercent"
                                onChange={(e) =>
                                    setIchraClass({
                                        ...ichraClass,
                                        eeCoveragePercentage: handlePercentChange(e.target.value),
                                    })
                                }
                                placeholder="Enter EE Coverage Percentage"
                                value={ichraClass.eeCoveragePercentage}
                            />
                            {ichraClass.topLineReimbursementStrategyId ===
                                TopLineReimbursementStrategies.CoverageLevelPercentage && (
                                <React.Fragment>
                                    <PercentTextField
                                        data-cy="esCoveragePercent"
                                        errors={errors?.esCoveragePercent}
                                        label="ES Coverage Percentage"
                                        name="esCoveragePercent"
                                        onChange={(e) =>
                                            setIchraClass({
                                                ...ichraClass,
                                                esCoveragePercentage: handlePercentChange(
                                                    e.target.value
                                                ),
                                            })
                                        }
                                        placeholder="Enter ES Coverage Percentage"
                                        value={ichraClass.esCoveragePercentage}
                                    />
                                    <PercentTextField
                                        data-cy="ecCoveragePercent"
                                        errors={errors?.ecCoveragePercent}
                                        label="EC Coverage Percentage"
                                        name="ecCoveragePercent"
                                        onChange={(e) =>
                                            setIchraClass({
                                                ...ichraClass,
                                                ecCoveragePercentage: handlePercentChange(
                                                    e.target.value
                                                ),
                                            })
                                        }
                                        placeholder="Enter EC Coverage Percentage"
                                        value={ichraClass.ecCoveragePercentage}
                                    />
                                    <PercentTextField
                                        data-cy="efCoveragePercent"
                                        errors={errors?.efCoveragePercent}
                                        label="EF Coverage Percentage"
                                        name="efCoveragePercent"
                                        onChange={(e) =>
                                            setIchraClass({
                                                ...ichraClass,
                                                efCoveragePercentage: handlePercentChange(
                                                    e.target.value
                                                ),
                                            })
                                        }
                                        placeholder="Enter EF Coverage Percentage"
                                        value={ichraClass.efCoveragePercentage}
                                    />
                                </React.Fragment>
                            )}
                            {ichraClass.topLineReimbursementStrategyId ===
                                TopLineReimbursementStrategies.SplitPrimaryHouseholdPercentage && (
                                <PercentTextField
                                    data-cy="otherHouseholdMemberCoveragePercent"
                                    errors={errors?.otherHouseholdMemberCoveragePercentage}
                                    label="Other Household Coverage Percentage"
                                    name="otherHouseholdMemberCoveragePercent"
                                    onChange={(e) =>
                                        setIchraClass({
                                            ...ichraClass,
                                            otherHouseholdMemberCoveragePercentage: handlePercentChange(
                                                e.target.value
                                            ),
                                        })
                                    }
                                    placeholder="Enter Other Household Member Coverage Percentage"
                                    value={ichraClass.otherHouseholdMemberCoveragePercentage}
                                />
                            )}
                        </Stack>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Select
                        errors={errors?.ageBandSizeId}
                        items={ageBandSizesArray}
                        label="Age Band Size"
                        name="ageBandSize"
                        onChange={(e) =>
                            setIchraClass({
                                ...ichraClass,
                                ageBandSizeId: Number(e.target.value),
                            })
                        }
                        optionText="name"
                        optionValue="value"
                        value={ichraClass.ageBandSizeId}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">64+ Reimbursement Amounts</Typography>
                    <Stack direction="row" spacing={2}>
                        <AgeBandCoverageLevelInputs
                            ageBandsOnChange={setAgeBandValue}
                            errors={errors?.ee64}
                            groupLevelAbbrev="Ee"
                            reimbursementRatio={ichraClass?.eeRatio?.toString()}
                            shouldDisable={isNotCustomClass}
                            sixtyFourReimbursement={ichraClass?.ee64?.toString()}
                        />
                        <AgeBandCoverageLevelInputs
                            ageBandsOnChange={setAgeBandValue}
                            errors={errors?.es64}
                            groupLevelAbbrev="Es"
                            reimbursementRatio={ichraClass?.esRatio?.toString()}
                            shouldDisable={isNotCustomClass}
                            sixtyFourReimbursement={ichraClass?.es64?.toString()}
                        />
                        <AgeBandCoverageLevelInputs
                            ageBandsOnChange={setAgeBandValue}
                            errors={errors?.ec64}
                            groupLevelAbbrev="Ec"
                            reimbursementRatio={ichraClass?.ecRatio?.toString()}
                            shouldDisable={isNotCustomClass}
                            sixtyFourReimbursement={ichraClass?.ec64?.toString()}
                        />
                        <AgeBandCoverageLevelInputs
                            ageBandsOnChange={setAgeBandValue}
                            errors={errors?.ef64}
                            groupLevelAbbrev="Ef"
                            reimbursementRatio={ichraClass?.efRatio?.toString()}
                            shouldDisable={isNotCustomClass}
                            sixtyFourReimbursement={ichraClass?.ef64?.toString()}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <AgeBandsTable
                        ageBand={getAgeBandSize(ichraClass.ageBandSizeId)}
                        ecCoverageLevelInputs={{
                            reimbursementRatio: ichraClass.ecRatio,
                            sixtyFourReimbursement: ichraClass.ec64 ?? 0,
                        }}
                        eeCoverageLevelInputs={{
                            reimbursementRatio: ichraClass.eeRatio,
                            sixtyFourReimbursement: ichraClass.ee64 ?? 0,
                        }}
                        efCoverageLevelInputs={{
                            reimbursementRatio: ichraClass.efRatio,
                            sixtyFourReimbursement: ichraClass.ef64 ?? 0,
                        }}
                        esCoverageLevelInputs={{
                            reimbursementRatio: ichraClass.esRatio,
                            sixtyFourReimbursement: ichraClass.es64 ?? 0,
                        }}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <NumberTextField
                        errors={errors?.dependentKickerThreshold}
                        helperText="This sets the number of dependents a household can have before the kicker will be added for each additional dependent."
                        label="Dependent Kicker Threshold"
                        name="dependentKickerThreshold"
                        onChange={(e) =>
                            setIchraClass({
                                ...ichraClass,
                                dependentKickerThreshold: Number(e.target.value),
                            })
                        }
                        value={ichraClass.dependentKickerThreshold}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <CurrencyTextField
                        disabled={[
                            TopLineReimbursementStrategies.CoverageLevelPercentage,
                            TopLineReimbursementStrategies.SplitPrimaryHouseholdPercentage,
                        ].includes(ichraClass.topLineReimbursementStrategyId)}
                        errors={errors?.additionalDependentKicker}
                        helperText={getAdditionalDependentKickerHelperText(
                            ichraClass.dependentKickerThreshold
                        )}
                        isOptional
                        label="Additional Dependent Kicker"
                        name="additionalDependentKicker"
                        onChange={(e) =>
                            setIchraClass({
                                ...ichraClass,
                                additionalDependentKicker: Number(e.target.value),
                            })
                        }
                        value={ichraClass.additionalDependentKicker}
                    />
                </Grid>
            </Grid>
        </Form>
    );
};

export default hot(module)(IchraClassForm);
