import { Create } from '@mui/icons-material';
import { Chip, IconButton, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import { clearApiActivity } from 'actions/clear';
import {
    GET_HOUSEHOLD_MEMBERS_ACTION,
    getHouseholdMembers,
} from 'actions/householdMember/getHouseholdMembers';
import { PROCESS_COVERAGE_LEVEL_FOR_REIMBURSEMENT_ACTION } from 'actions/householdMember/processCoverageLevelForReimbursement';
import { GET_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION } from 'actions/marketplacePlan/getMarketplacePlanRatesForUser';
import { GET_MEDISHARE_PLANS_FOR_USER_ACTION } from 'actions/medishare/getMediSharePlansAndRatesForUser';
import {
    GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION,
    getPathwayBlueprintDataForUser,
} from 'actions/pathwayBlueprint/getPathwayBlueprintDataForUser';
import {
    GET_SELECTED_PLANS_FOR_REVIEW_ACTION,
    getSelectedPlansForReview,
} from 'actions/selectedPlan/getSelectedPlansForReview';
import { listAncillaryBenefits } from 'actions/teamBenefit/listAncillaryBenefits';
import { getCurrentUserBudget } from 'actions/user/getCurrentUserBudget';
import {
    GET_HOUSEHOLD_ELIGIBILITY_ACTION,
    getHouseholdEligibility,
} from 'actions/user/getHouseholdEligibility';
import { GET_USER_PROFILE_ACTION, getUserProfile } from 'actions/user/getUserProfile';
import {
    EnrollmentStatuses,
    HouseholdEligibilityStatus,
    HouseholdMemberTypes,
    OtherCoverageEligibilities,
} from 'api/generated/enums';
import { IHouseholdMemberDto, IMemberEligibility, IUser } from 'api/generated/models';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import InformationIconTooltip from 'components/InformationIconTooltip';
import ShopHeaderContainer from 'components/ShopHeaderContainer';
import Tooltip from 'components/Tooltip';
import ScheduleAdvisementModalLink from 'components/helpComponents/ScheduleAdvisementModalLink';
import { push } from 'connected-react-router';
import useModalState from 'hooks/useModalState';
import useQuery from 'hooks/useQuery';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import isUndefined from 'lodash/isUndefined';
import without from 'lodash/without';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import ChangeHouseholdCoverageModal from 'pages/shop/ChangeHouseholdCoverageModal';
import { BenefitsMarket, QUERY_PARAM, getDefaultBenefitsMarket } from 'pages/shop/ShopFilters';
import { ShoppingContext } from 'pages/shop/shopPageUtilities';
import {
    clearShoppingState,
    setShoppingDisabledMembers,
    updateShoppingMembersAndGetRates,
} from 'pages/shop/shoppingActions';
import useShoppingConfiguration from 'pages/shop/useShoppingConfiguration';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { IHouseholdMemberWithAge } from 'reducers/householdMembers';
import { SELECTIONS_PATH, TEAMS_SELECTIONS_PATH } from 'routers/routes';
import { hasApiActivity, hasCompletedRequest, hasCompletedRequests } from 'selectors/activity';
import styled from 'styled-components';
import { hasMemberType } from 'utilities/household';
import { getEligibilityData } from 'utilities/householdEligibility';
import { arrayHasValue, hasValue } from 'utilities/index';

export type RenderCheckBoxProps = {
    disabledOverride?: boolean;
    householdMembers?: IHouseholdMemberWithAge[];
    id: string;
    index: number;
    isIchraShoppingModal: boolean;
    isUnclaimed: boolean;
    name: string | undefined;
    needsCoverage: boolean | undefined;
    onCheckboxChange: (
        e: React.ChangeEvent<HTMLInputElement>,
        checked: boolean,
        householdMemberId: string
    ) => void;
};

export const getEligibilityInfo = (householdEligibility: IMemberEligibility[], id: string) => {
    const eligibilityStatus = householdEligibility.find((x) => x.memberId === id)
        ?.eligibilityStatus;
    const otherEligibilityStatus = householdEligibility.find((x) => x.memberId === id)
        ?.otherCoverageEligibility;
    return { eligibilityStatus, otherEligibilityStatus };
};

export const ADVISEMENT_SCHEDULED_MESSAGE =
    'You have scheduled an advisement, check your calendly confirmation email for details.';

const canBeSelectedForPlans = (
    householdEligibility: IMemberEligibility[],
    id: string,
    isUnclaimed: boolean,
    shouldAllowUnclaimed: boolean,
    needsCoverage: boolean | undefined,
    onIchraPathway?: boolean
) => {
    const { eligibilityStatus, otherEligibilityStatus } = getEligibilityInfo(
        householdEligibility,
        id
    );
    const hasDisqualifyingEligibilities = hasAnyDisqualifyingEligibilities(
        eligibilityStatus,
        otherEligibilityStatus,
        onIchraPathway
    );

    return (
        !hasDisqualifyingEligibilities && (!isUnclaimed || shouldAllowUnclaimed) && !!needsCoverage
    );
};

export const hasAnyDisqualifyingEligibilities = (
    status: HouseholdEligibilityStatus | undefined,
    otherEligibility: OtherCoverageEligibilities | undefined,
    onIchraPathway?: boolean
) => {
    let hasDisqualifyingStatus = !!(
        (status as HouseholdEligibilityStatus) &
        (HouseholdEligibilityStatus.Medicaid |
            HouseholdEligibilityStatus.Medicare |
            HouseholdEligibilityStatus.CHIP)
    );
    if (
        !onIchraPathway &&
        ![OtherCoverageEligibilities.SpouseEmployer, OtherCoverageEligibilities.None].contains(
            otherEligibility
        )
    ) {
        hasDisqualifyingStatus = !!(
            (status as HouseholdEligibilityStatus) & HouseholdEligibilityStatus.Other
        );
    }
    return hasDisqualifyingStatus;
};

const ShopHeader = ({
    flowTaskId,
    isIchraFlow = false,
    isTeamManagementPage,
    showPreviousButton,
}: {
    flowTaskId: string | undefined;
    isIchraFlow: boolean | undefined;
    isTeamManagementPage: boolean | undefined;
    showPreviousButton: boolean | undefined;
}) => {
    const dispatch = useThunkDispatch();
    const {
        ancillaryBenefits,
        hasCompletedInitialRequests,
        hasCompletedMarketplaceRequests,
        hasCompletedMedishareRequest,
        hasInitialRequestActivity,
        hasPendingPlanRequests,
        householdEligibility,
        householdMembers,
        members,
        numberOfPlansForReview,
        pathwayUser,
        selectedHhms,
    } = useSelector((state: AppStore) => ({
        ancillaryBenefits: state.ancillaryBenefits,
        hasCompletedInitialRequests: hasCompletedRequests(
            state,
            GET_USER_PROFILE_ACTION,
            GET_HOUSEHOLD_MEMBERS_ACTION,
            GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION,
            GET_HOUSEHOLD_ELIGIBILITY_ACTION,
            GET_SELECTED_PLANS_FOR_REVIEW_ACTION
        ),
        hasCompletedMarketplaceRequests: hasCompletedRequest(
            state,
            GET_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION
        ),
        hasCompletedMedishareRequest: hasCompletedRequest(
            state,
            GET_MEDISHARE_PLANS_FOR_USER_ACTION
        ),
        hasInitialRequestActivity: hasApiActivity(
            state,
            GET_USER_PROFILE_ACTION,
            GET_HOUSEHOLD_MEMBERS_ACTION,
            GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION,
            GET_HOUSEHOLD_ELIGIBILITY_ACTION,
            GET_SELECTED_PLANS_FOR_REVIEW_ACTION
        ),
        hasPendingPlanRequests: hasApiActivity(
            state,
            GET_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION,
            GET_MEDISHARE_PLANS_FOR_USER_ACTION,
            PROCESS_COVERAGE_LEVEL_FOR_REIMBURSEMENT_ACTION
        ),
        householdEligibility: state.householdEligibility,
        householdMembers: state.householdMembers,
        members: state.shoppingState.members,
        numberOfPlansForReview: state.selectedPlansForReview.selectedPlans?.length,
        pathwayUser: state.pathwayUser,
        selectedHhms: state.shoppingState.selectedHouseholdMembers,
    }));
    const {
        address,
        isCurrent,
        isInIchraPathway,
        memberVerifiedInfo,
        user,
        userId,
        userProfile,
        yearlyUserInfo,
    } = useUserProps();
    const { teamId } = useTeamProps();
    const [query] = useQuery();
    const shoppingConfigurationId = useShoppingConfiguration();
    const {
        benefitsMarket,
        hasCompletedInitialLoading,
        hasFetchedPlans,
        setBenefitsMarket,
        setHasFetchedPlans,
        setHasCompletedInitialLoading,
    } = useContext(ShoppingContext);
    const [recipients, setRecipients] = useState<(IHouseholdMemberDto | IUser)[]>();
    const [notNeedingCoverage, setNotNeedingCoverage] = useState<(IHouseholdMemberDto | IUser)[]>(
        []
    );
    const [isPrimaryMedicare, setIsPrimaryMedicare] = useState(false);
    const isOffExchange = benefitsMarket === BenefitsMarket.OffExchange;
    const isMedishare = benefitsMarket === BenefitsMarket.SharingPrograms;
    const isUnclaimedAllowed = isInIchraPathway || isOffExchange;
    const year = user?.activeDate?.getYear() ?? 0;
    const redirectToDashboard = !arrayHasValue(ancillaryBenefits);

    const {
        closeModal: closeChangeHouseholdCoverageModal,
        isVisible: isChangeHouseholdCoverageModalVisible,
        openModal: openChangeHouseholdCoverageModal,
    } = useModalState();

    const handleChangeHouseholdCoverageModalClose = () => {
        closeChangeHouseholdCoverageModal();
        dispatch(getUserProfile(userId, isCurrent));
    };

    const setMemberCheckedState = useCallback(() => {
        const {
            eligibilityStatus: primaryEligibilityStatus,
            otherEligibilityStatus,
        } = getEligibilityInfo(householdEligibility, userId);
        const membersCheckedState = { ...members };

        membersCheckedState[userId] =
            !hasAnyDisqualifyingEligibilities(
                primaryEligibilityStatus,
                otherEligibilityStatus,
                isInIchraPathway
            ) && (yearlyUserInfo?.needsMajorMedicalCoverage as boolean);

        householdMembers.forEach(({ householdMemberId, householdMemberTypeId, needsCoverage }) => {
            const canSelectForPlans = canBeSelectedForPlans(
                householdEligibility,
                householdMemberId,
                householdMemberTypeId === HouseholdMemberTypes.Unclaimed,
                isUnclaimedAllowed,
                needsCoverage,
                isInIchraPathway
            );

            membersCheckedState[householdMemberId] = canSelectForPlans;
        });

        dispatch(
            setShoppingDisabledMembers(
                Object.keys(membersCheckedState).filter((x) => !membersCheckedState[x])
            )
        );
        dispatch(
            updateShoppingMembersAndGetRates(
                userId,
                membersCheckedState,
                year,
                shoppingConfigurationId
            )
        );
    }, [
        dispatch,
        householdEligibility,
        householdMembers,
        isInIchraPathway,
        isUnclaimedAllowed,
        members,
        shoppingConfigurationId,
        userId,
        year,
        yearlyUserInfo?.needsMajorMedicalCoverage,
    ]);

    const getCheckboxTooltipText = (
        isUnclaimedDisabled: boolean,
        name: string | undefined,
        needsCoverage: boolean | undefined,
        hasDisqualifyingEligibilities: boolean,
        eligibilityData: {
            name: string;
            [x: string]: number | string;
        }[],
        member?: IHouseholdMemberDto | IUser
    ) => {
        let title;
        if (isUnclaimedDisabled) {
            title =
                `Since ${name} is not claimed on your taxes, you cannot shop for them here. Please wait to speak with ` +
                'an Advisor about their benefits, and feel free to reach out to your Remodel Health representative ' +
                'with any questions.';
        } else if (hasDisqualifyingEligibilities) {
            title = `This member is eligible for ${eligibilityData
                .map((x) => x.name)
                .joinSerialComma()}`;
        } else if (!needsCoverage) {
            title = 'This member is marked as not needing major medical coverage';
        } else if (isInIchraPathway) {
            title = (
                <span data-cy="primary-member-tooltip">
                    Age on Effective Date: {member?.dateOfBirth?.getAge(user?.activeDate)}
                    <br />
                    Gender:{' '}
                    {(member as IUser).memberVerifiedInfos?.[0]?.gender ??
                        (member as IHouseholdMemberDto).gender}
                    <br />
                    ZIP: {address?.zip}
                </span>
            );
        } else {
            title = 'We need at least one member checked to successfully get plans and programs.';
        }
        return title;
    };

    const handleButtonClick = () => {
        dispatch(
            push(
                isTeamManagementPage
                    ? generatePath(TEAMS_SELECTIONS_PATH, { teamId, userId })
                    : SELECTIONS_PATH
            )
        );
    };

    const handleCheckboxChange = async (
        e: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => {
        dispatch(
            updateShoppingMembersAndGetRates(
                userId,
                {
                    ...members,
                    [e.target.name]: checked,
                },
                year,
                shoppingConfigurationId
            )
        );
    };

    const renderCheckbox = ({
        id,
        name,
        needsCoverage,
        isUnclaimed,
        index,
    }: RenderCheckBoxProps) => {
        const canSelect = canBeSelectedForPlans(
            householdEligibility,
            id,
            isUnclaimed,
            isUnclaimedAllowed,
            needsCoverage,
            isInIchraPathway
        );
        const isCheckboxDisabled =
            members[id] && without(Object.values(members), false).length === 1;
        const disabled =
            isCheckboxDisabled ||
            !canSelect ||
            !hasFetchedPlans ||
            hasPendingPlanRequests ||
            isInIchraPathway;
        const checkbox = !isUndefined(members[id]) ? (
            <Checkbox
                checked={members[id]}
                className="my-0 py-0"
                data-cy={`member-${index}`}
                disabled={disabled}
                label={name as string}
                name={id}
                onChange={async (e, checked) => handleCheckboxChange(e, checked)}
            />
        ) : (
            <React.Fragment />
        );
        const { eligibilityStatus, otherEligibilityStatus } = getEligibilityInfo(
            householdEligibility,
            id
        );
        const hasDisqualifyingEligibilities = hasAnyDisqualifyingEligibilities(
            eligibilityStatus,
            otherEligibilityStatus,
            isInIchraPathway
        );
        const eligibilityData = getEligibilityData(eligibilityStatus);
        const title = getCheckboxTooltipText(
            isUnclaimed && !isUnclaimedAllowed,
            name,
            needsCoverage,
            hasDisqualifyingEligibilities,
            eligibilityData
        );
        return (
            <React.Fragment>
                {disabled ? <Tooltip title={title}>{checkbox}</Tooltip> : checkbox}
            </React.Fragment>
        );
    };

    const renderChip = (
        member: IHouseholdMemberDto | IUser,
        memberId: string,
        needsCoverage: boolean | undefined
    ) => {
        const { eligibilityStatus, otherEligibilityStatus } = getEligibilityInfo(
            householdEligibility,
            memberId
        );
        const hasDisqualifyingEligibilities = hasAnyDisqualifyingEligibilities(
            eligibilityStatus,
            otherEligibilityStatus,
            isInIchraPathway
        );
        const eligibilityData = getEligibilityData(eligibilityStatus);
        const title = getCheckboxTooltipText(
            false,
            member.firstName,
            needsCoverage,
            hasDisqualifyingEligibilities,
            eligibilityData,
            member
        );

        return (
            <Tooltip key={member.firstName} title={title}>
                <Chip
                    color={needsCoverage ? 'secondary' : 'default'}
                    label={member.firstName}
                    style={{ textDecoration: !needsCoverage ? 'line-through' : '' }}
                />
            </Tooltip>
        );
    };

    useEffect(() => {
        const checkUnclaimed =
            hasMemberType(householdMembers, HouseholdMemberTypes.Unclaimed) && !isUnclaimedAllowed;
        if (
            hasValue(pathwayUser) &&
            (checkUnclaimed || isIchraFlow || hasCompletedInitialRequests)
        ) {
            setMemberCheckedState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasCompletedInitialRequests, isUnclaimedAllowed, pathwayUser]);

    useEffect(() => {
        const peopleToAdd: (IHouseholdMemberDto | IUser)[] = [];
        const peopleNotNeedingCoverage: (IHouseholdMemberDto | IUser)[] = [];
        const memberIdsWithMedicare = householdEligibility
            ?.filter((he) => he.eligibilityStatus === HouseholdEligibilityStatus.Medicare)
            .map((hem) => hem.memberId);

        const _isPrimaryMedicare = memberIdsWithMedicare.contains(userId);
        if (yearlyUserInfo?.needsMajorMedicalCoverage && !_isPrimaryMedicare) {
            peopleToAdd.push(user as IUser);
        } else {
            peopleNotNeedingCoverage.push(user as IUser);
        }

        const peopleWhoNeedCoverage = householdMembers.filter(
            (hhm) => hhm.needsCoverage && !memberIdsWithMedicare.includes(hhm.householdMemberId)
        );
        const unSelectedHouseholdMembers = householdMembers.filter(
            (hhm) => !peopleWhoNeedCoverage.includes(hhm)
        );

        peopleToAdd.push(...peopleWhoNeedCoverage);
        peopleNotNeedingCoverage.push(...unSelectedHouseholdMembers);

        setRecipients(peopleToAdd);
        setNotNeedingCoverage(peopleNotNeedingCoverage);
        setIsPrimaryMedicare(_isPrimaryMedicare);
    }, [householdEligibility, householdMembers, selectedHhms, user, userId, yearlyUserInfo]);

    useEffect(() => {
        if (isIchraFlow) {
            dispatch(listAncillaryBenefits(userId, true));
        }
    }, [dispatch, isIchraFlow, userId]);

    // get the benefits market and fetch the appropriate plans
    useEffect(() => {
        const queryBenefitsMarket = query.has(QUERY_PARAM.MARKET)
            ? (query.get(QUERY_PARAM.MARKET) as BenefitsMarket)
            : getDefaultBenefitsMarket(shoppingConfigurationId, pathwayUser);
        const hasPlans =
            ([BenefitsMarket.AcaMarketplace, BenefitsMarket.OffExchange].contains(benefitsMarket) &&
                hasCompletedMarketplaceRequests) ||
            (isMedishare && hasCompletedMedishareRequest);
        setBenefitsMarket?.(queryBenefitsMarket);
        setHasFetchedPlans?.(hasPlans);
    }, [
        benefitsMarket,
        hasCompletedInitialLoading,
        hasCompletedMarketplaceRequests,
        hasCompletedMedishareRequest,
        isMedishare,
        pathwayUser,
        query,
        setBenefitsMarket,
        setHasFetchedPlans,
        shoppingConfigurationId,
    ]);

    const dispatchShoppingRequests = useCallback(async () => {
        await dispatch(getUserProfile(userId, isCurrent));
        await dispatch(getHouseholdMembers(userId));
        await dispatch(getPathwayBlueprintDataForUser(userId, year));
        await dispatch(getHouseholdEligibility(userId, year));
        await dispatch(getSelectedPlansForReview(userId));
        await dispatch(getCurrentUserBudget(teamId, userId));
    }, [dispatch, isCurrent, teamId, userId, year]);

    // send the initial requests needed for the shopping page
    useEffect(() => {
        if (
            !hasCompletedInitialRequests &&
            !hasInitialRequestActivity &&
            !hasCompletedInitialLoading
        ) {
            dispatchShoppingRequests();
            setHasCompletedInitialLoading?.(true);
        }

        return () => {
            dispatch(clearShoppingState());
            dispatch(clearApiActivity());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <ShopHeaderContainer
                leftSide={
                    <Grid container>
                        <ShoppingForHeader>
                            You&apos;re currently shopping for:
                            {isInIchraPathway && (
                                <IconButton size="medium">
                                    <Create onClick={openChangeHouseholdCoverageModal} />
                                </IconButton>
                            )}
                        </ShoppingForHeader>
                        {isInIchraPathway ? (
                            <Stack
                                direction="row"
                                flexWrap="wrap"
                                gap={1}
                                margin={2}
                                marginBottom={0}
                            >
                                {recipients?.map((member) => (
                                    <React.Fragment key={member.firstName}>
                                        {renderChip(
                                            member,
                                            (member as IHouseholdMemberDto).householdMemberId ??
                                                (member as IUser).userId,
                                            true
                                        )}
                                    </React.Fragment>
                                ))}
                                {notNeedingCoverage.map((member) => (
                                    <React.Fragment key={member.firstName}>
                                        {renderChip(
                                            member,
                                            (member as IHouseholdMemberDto).householdMemberId ??
                                                (member as IUser).userId,
                                            false
                                        )}
                                    </React.Fragment>
                                ))}
                            </Stack>
                        ) : (
                            <Stack direction={{ sm: 'row', xs: 'column' }} mb={1}>
                                <Grid item sx={{ alignItems: 'center', ml: 3, mt: 1 }}>
                                    {renderCheckbox({
                                        id: userId,
                                        index: 0,
                                        isUnclaimed: false,
                                        name: userProfile.user?.firstName,
                                        needsCoverage: yearlyUserInfo?.needsMajorMedicalCoverage,
                                    } as RenderCheckBoxProps)}
                                    <InformationIconTooltip
                                        data-cy="primary-member-tooltip-trigger"
                                        style={{
                                            marginLeft: '5px',
                                            position: 'relative',
                                            top: '-4px',
                                        }}
                                        title={
                                            <span data-cy="primary-member-tooltip">
                                                Age on Effective Date:{' '}
                                                {user?.dateOfBirth?.getAge(user?.activeDate)}
                                                <br />
                                                Gender: {memberVerifiedInfo?.gender}
                                                <br />
                                                ZIP: {address?.zip}
                                            </span>
                                        }
                                    />
                                </Grid>
                                {householdMembers.map((member, index) => (
                                    <Grid
                                        item
                                        key={member.keyId}
                                        sx={{ alignItems: 'center', ml: 3, mt: 1 }}
                                    >
                                        {renderCheckbox({
                                            id: member.householdMemberId,
                                            index: index + 1,
                                            isUnclaimed:
                                                member.householdMemberTypeId ===
                                                HouseholdMemberTypes.Unclaimed,
                                            name: member.firstName,
                                            needsCoverage: member.needsCoverage,
                                        } as RenderCheckBoxProps)}
                                        <InformationIconTooltip
                                            data-cy={`member-${index}-tooltip-trigger`}
                                            style={{
                                                marginLeft: '5px',
                                                position: 'relative',
                                                top: '-4px',
                                            }}
                                            title={
                                                <span data-cy={`member-${index}-tooltip`}>
                                                    Age on Effective Date:{' '}
                                                    {member.dateOfBirth.getAge(user?.activeDate)}
                                                    <br />
                                                    Gender: {member.gender}
                                                </span>
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Stack>
                        )}
                        {!isTeamManagementPage && (
                            <Grid item mb={{ lg: 0, xs: 2 }} ml={1} mt={2} xs={12}>
                                {yearlyUserInfo?.enrollmentStatus?.value ===
                                EnrollmentStatuses.AdvisementScheduled ? (
                                    <span>{ADVISEMENT_SCHEDULED_MESSAGE}</span>
                                ) : (
                                    <span>
                                        Need help making a decision? You can{' '}
                                        <ScheduleAdvisementModalLink isIchraFlow={isIchraFlow} />.
                                    </span>
                                )}
                            </Grid>
                        )}
                    </Grid>
                }
                rightSide={
                    isIchraFlow ? (
                        <IchraFlowButtons
                            currentTaskId={flowTaskId as string}
                            displayAsRow
                            hideContinueButton
                            redirectToDashboard={redirectToDashboard}
                            showPreviousButton={showPreviousButton}
                            showWaiveButton
                        />
                    ) : (
                        <Grid item>
                            <Button
                                color="primary"
                                data-cy="review-selections"
                                disabled={!numberOfPlansForReview}
                                onClick={handleButtonClick}
                                variant="contained"
                            >
                                Review {isCurrent ? 'Your ' : ''}Selections
                                {hasValue(numberOfPlansForReview) && ` (${numberOfPlansForReview})`}
                            </Button>
                        </Grid>
                    )
                }
            />
            {isChangeHouseholdCoverageModalVisible && (
                <ChangeHouseholdCoverageModal
                    householdEligibility={householdEligibility}
                    householdMembers={householdMembers}
                    isIchraFlow={isIchraFlow}
                    isPrimaryMedicare={isPrimaryMedicare}
                    isPrimaryNeedingCoverage={yearlyUserInfo?.needsMajorMedicalCoverage ?? false}
                    onClose={handleChangeHouseholdCoverageModalClose}
                    selectedHhms={selectedHhms}
                />
            )}
        </React.Fragment>
    );
};

const ShoppingForHeader = styled.p.attrs(() => ({ className: 'py-0 my-0 col-12' }))`
    font-size: 12px;
`;

export default hot(module)(ShopHeader);
