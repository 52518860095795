import { getAdpBenefitsEligibilityClassCodes } from 'actions/adp/getAdpBenefitsEligibilityClassCodes';
import {
    AdpIntegrationStatuses,
    HrsIntegrationProviders,
    MajorMedicalWaitingPeriodIds,
    PaylocityIntegrationStatuses,
    TerminationDateDelays,
} from 'api/generated/enums';
import { ITeam } from 'api/generated/models';
import {
    EditPayrollIntegrationConfiguration,
    SetPayrollSystemDeduction,
} from 'api/generated/permissions';
import EditableSelectAttribute from 'components/EditableSelectAttribute';
import EditableTextAttribute from 'components/EditableTextAttribute';
import { ISaveEditableTextField } from 'components/EditableTextField';
import EditableYesNoSelectAttribute from 'components/EditableYesNoSelectAttribute';
import {
    MAJOR_MEDICAL_WAITING_PERIOD_ITEMS,
    majorMedicalWaitingPeriodIdNames,
} from 'constants/majorMedicalWaitingPeriods';
import {
    TERMINATION_DATE_DELAY_ITEMS,
    terminationDateDelayNames,
} from 'constants/terminationDateDelays';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import EditableProfileAttribute from 'pages/profile/EditableProfileAttribute';
import AdpBenefitsEligibilityClassCodeInfo from 'pages/teamProfile/AdpBenefitsEligibilityClassCodeInfo';
import AdpBenefitsEligibilityClassCodesModal from 'pages/teamProfile/AdpBenefitsEligibilityClassCodesModal';
import AdpIntegrationSection from 'pages/teamProfile/AdpIntegrationSection';
import PaylocityIntegrationSection from 'pages/teamProfile/PaylocityIntegrationSection';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors/index';
import { onChange } from 'utilities/forms';
import { enumToString, hasValue } from 'utilities/index';
import { number, string } from 'yup';

const majorMedicalWaitingPeriodOffsetMax = 30;
const adpMajorMedicalDeductionCodeMaxLength = 50;
const deductionCodeSchema = string()
    .max(adpMajorMedicalDeductionCodeMaxLength)
    .label('Deduction Code');
const inputNumberProps = {
    min: 0,
    placeholder: 'Enter a number',
    type: 'number',
};

const HrsIntegrationSection = ({ save }: { save: ISaveEditableTextField<ITeam> }) => {
    const dispatch = useThunkDispatch();
    const {
        adpBenefitsEligibilityClassCodes,
        canEditHrsIntegrationConfiguration,
        canSetPayrollSystemDeduction,
        hrsIntegrationProvider,
    } = useSelector((state: AppStore) => ({
        adpBenefitsEligibilityClassCodes: state.adpBenefitsEligibilityClassCodes,
        canEditHrsIntegrationConfiguration: hasSomePermissions(
            state,
            EditPayrollIntegrationConfiguration
        ),
        canSetPayrollSystemDeduction: hasSomePermissions(state, SetPayrollSystemDeduction),
        hrsIntegrationProvider: state.teamProfile.team?.hrsIntegrationProvider,
    }));
    const { team } = useTeamProps();
    const isIntegrationConnected =
        team?.hrsIntegrationProvider === HrsIntegrationProviders.Adp
            ? team.adpIntegrationStatus === AdpIntegrationStatuses.Connected
            : team?.paylocityIntegrationStatus === PaylocityIntegrationStatuses.Connected;
    const [majorMedicalWaitingPeriodId, setMajorMedicalWaitingPeriodId] = useState<
        MajorMedicalWaitingPeriodIds | undefined
    >(team?.majorMedicalWaitingPeriodId);
    const [majorMedicalWaitingPeriodOffset, setMajorMedicalWaitingPeriodOffset] = useState<
        MajorMedicalWaitingPeriodIds | undefined
    >(team?.majorMedicalWaitingPeriodOffset);
    const [terminationDateDelayId, setTerminationDateDelayId] = useState<
        TerminationDateDelays | undefined
    >(team?.terminationDateDelayId);
    const [adpPostTaxDeductionCode, setAdpPostTaxDeductionCode] = useState(
        team?.adpPostTaxDeductionCode ?? ''
    );
    const [adpWageUpDeductionCode, setAdpWageUpDeductionCode] = useState(
        team?.adpWageUpDeductionCode ?? ''
    );
    const [adpPreTaxDeductionCode, setAdpPreTaxDeductionCode] = useState(
        team?.adpPreTaxDeductionCode ?? ''
    );
    const [adpTaxFreeReimbursementCode, setAdpTaxFreeReimbursementCode] = useState(
        team?.adpTaxFreeReimbursementDeductionCode ?? ''
    );
    const [
        setWageUpAndWithholdingOnPayrollReportShare,
        setSetWageUpAndWithholdingOnPayrollReportShare,
    ] = useState(team?.setWageUpAndWithholdingOnPayrollReportShare?.toString() ?? '');
    const {
        closeModal: closeBenefitsEligibilityClassCodesModal,
        isVisible: isBenefitsEligibilityClassCodesModalVisible,
        openModal: openBenefitsEligibilityClassCodesModalVisible,
    } = useModalState();
    useEffect(() => {
        if (isIntegrationConnected) {
            dispatch(getAdpBenefitsEligibilityClassCodes(team?.teamId));
        }
    }, [dispatch, isIntegrationConnected, team?.teamId]);
    const hrsProviderName = enumToString(
        HrsIntegrationProviders,
        team?.hrsIntegrationProvider ?? HrsIntegrationProviders.NotConfigured
    );

    return (
        <React.Fragment>
            {hrsIntegrationProvider === HrsIntegrationProviders.Adp && (
                <AdpIntegrationSection save={save} />
            )}
            {hrsIntegrationProvider === HrsIntegrationProviders.Paylocity && (
                <PaylocityIntegrationSection save={save} />
            )}
            {isBenefitsEligibilityClassCodesModalVisible && (
                <AdpBenefitsEligibilityClassCodesModal
                    classCodes={adpBenefitsEligibilityClassCodes
                        .map((classCode) => classCode)
                        .filter(hasValue)}
                    onClose={closeBenefitsEligibilityClassCodesModal}
                />
            )}
            {isIntegrationConnected && (
                <React.Fragment>
                    {canEditHrsIntegrationConfiguration && (
                        <React.Fragment>
                            <EditableSelectAttribute
                                formatter={() =>
                                    hasValue(majorMedicalWaitingPeriodId)
                                        ? majorMedicalWaitingPeriodIdNames[
                                              majorMedicalWaitingPeriodId
                                          ]
                                        : 'Unknown'
                                }
                                infoTooltip="Amount of time after a member's start date in the payroll system that the active date will be set for new hires."
                                items={MAJOR_MEDICAL_WAITING_PERIOD_ITEMS}
                                label="Major Medical Waiting Period"
                                name="majorMedicalWaitingPeriodId"
                                onChange={onChange(setMajorMedicalWaitingPeriodId)}
                                optionText="text"
                                optionValue="value"
                                save={save}
                                validationSchema={string()
                                    .required()
                                    .label('Major Medical Waiting Period')}
                                value={majorMedicalWaitingPeriodId}
                            />
                            {majorMedicalWaitingPeriodId?.toString() ===
                                MajorMedicalWaitingPeriodIds.OffsetThenFirstDayOfNextMonth.toString() && (
                                <EditableTextAttribute
                                    {...inputNumberProps}
                                    infoTooltip={
                                        "When using the Offset Then First of Next Month Waiting Period, this sets the number of days from a workers hire date before setting the first of the next month. For example, if a worker starts on Jan 10th, and the offset is 30 days, the worker's active date will be 3/1"
                                    }
                                    isOptional
                                    label="Major Medical Waiting Offset"
                                    name="majorMedicalWaitingPeriodOffset"
                                    onChange={onChange(setMajorMedicalWaitingPeriodOffset)}
                                    save={save}
                                    validationSchema={number()
                                        .min(0)
                                        .max(majorMedicalWaitingPeriodOffsetMax)
                                        .label('Major Medical Waiting Period Offset')}
                                    value={majorMedicalWaitingPeriodOffset}
                                />
                            )}
                            <EditableSelectAttribute
                                formatter={() =>
                                    hasValue(terminationDateDelayId)
                                        ? terminationDateDelayNames[terminationDateDelayId]
                                        : 'Unknown'
                                }
                                infoTooltip="Amount of time after the payroll system's termination date that the Termination Date will be set to."
                                items={TERMINATION_DATE_DELAY_ITEMS}
                                label="Termination Date Delay"
                                name="terminationDateDelayId"
                                onChange={onChange(setTerminationDateDelayId)}
                                optionText="text"
                                optionValue="value"
                                save={save}
                                validationSchema={string()
                                    .required()
                                    .label('Termination Date Delay')}
                                value={terminationDateDelayId}
                            />
                        </React.Fragment>
                    )}
                    {canSetPayrollSystemDeduction && (
                        <React.Fragment>
                            <EditableTextAttribute
                                infoTooltip={`The deduction code in ${hrsProviderName} that will be used for withholdings for post-tax major medical plan costs. This corresponds with the Post-Tax Withholding section of the Payroll Report. This should be configured as a post-tax code in ${hrsProviderName}.`}
                                label="Post-tax Deduction Code"
                                name="adpPostTaxDeductionCode"
                                onChange={onChange(setAdpPostTaxDeductionCode)}
                                save={save}
                                validationSchema={deductionCodeSchema}
                                value={adpPostTaxDeductionCode}
                            />
                            <EditableTextAttribute
                                infoTooltip={`The deduction code in ${hrsProviderName} that will be used for withholding for pre-tax major medical plan costs. This corresponds with the Pre-Tax Withholding section of the Payoll Report. This should be configured as a pre-tax code in ${hrsProviderName}.`}
                                label="Pre-tax Deduction Code"
                                name="adpPreTaxDeductionCode"
                                onChange={onChange(setAdpPreTaxDeductionCode)}
                                save={save}
                                validationSchema={deductionCodeSchema}
                                value={adpPreTaxDeductionCode}
                            />
                            <EditableTextAttribute
                                infoTooltip={`The deduction code in ${hrsProviderName} that will be used for Wage+ amounts. This corresponds with the Wage+ section of the Payroll Report. This should be configured as a pre-tax code in ${hrsProviderName}.`}
                                label="Wage+ Deduction Code"
                                name="adpWageUpDeductionCode"
                                onChange={onChange(setAdpWageUpDeductionCode)}
                                save={save}
                                validationSchema={deductionCodeSchema}
                                value={adpWageUpDeductionCode}
                            />
                            <EditableTextAttribute
                                infoTooltip={`The deduction code in ${hrsProviderName} that will be used for Tax Free Reimbursements. This corresponds with the Tax-Free Reimbursement section of the Payroll Report. This should be configured to a post-tax code in ${hrsProviderName}.`}
                                label="Tax Free Reimbursement Deduction Code"
                                name="adpTaxFreeReimbursementDeductionCode"
                                onChange={onChange(setAdpTaxFreeReimbursementCode)}
                                save={save}
                                validationSchema={deductionCodeSchema}
                                value={adpTaxFreeReimbursementCode}
                            />
                            <EditableYesNoSelectAttribute
                                infoTooltip="If Wage+ amounts and Withholdings will be set in the customer's payroll system when their Payroll Report is shared."
                                label="Set Wage+ and Withholding on Payroll Report Share"
                                name="setWageUpAndWithholdingOnPayrollReportShare"
                                onChange={onChange(setSetWageUpAndWithholdingOnPayrollReportShare)}
                                save={save}
                                value={setWageUpAndWithholdingOnPayrollReportShare}
                            />
                            <EditableProfileAttribute
                                content={
                                    <AdpBenefitsEligibilityClassCodeInfo
                                        data={adpBenefitsEligibilityClassCodes}
                                    />
                                }
                                infoTooltip={`Members that do not belong to one of these Benefits Eligibility Class Codes in ${hrsProviderName} will be excluded from the ${hrsProviderName} integration.`}
                                label="Benefits Eligibility Class Codes"
                                onClick={openBenefitsEligibilityClassCodesModalVisible}
                            />
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default hot(module)(HrsIntegrationSection);
