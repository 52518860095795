import { Divider, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { PathwayTypes, ShoppingConfigurationIds } from 'api/generated/enums';
import { IPathwayUserDto } from 'api/generated/models';
import Checkbox from 'components/Checkbox';
import InformationIconTooltip from 'components/InformationIconTooltip';
import RadioGroup from 'components/RadioGroup';
import { BODY_FONT_COLOR, HEADER_FONT_COLOR } from 'constants/styleConstants';
import useQuery from 'hooks/useQuery';
import kebabCase from 'lodash/kebabCase';
import ProviderFilterContent from 'pages/shop/ProviderFilterContent';
import { ShoppingContext } from 'pages/shop/shopPageUtilities';
import useShoppingConfiguration from 'pages/shop/useShoppingConfiguration';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { uniqueMarketplaceCarriersSelector } from 'selectors/marketplace';
import { CSR_LABEL, uniqueMetalLevelsSelector, uniquePlanTypesSelector } from 'selectors/shopping';
import { getNumberOfSetBitsOnFlag, hasFlag, hasValue } from 'utilities';
import { onChange } from 'utilities/forms';
import { isIchraPathwayType } from 'utilities/pathways';

export enum BenefitsMarket {
    AcaMarketplace = 'aca-marketplace',
    OffExchange = 'off-exchange',
    SharingPrograms = 'sharing-programs',
}

export const getDefaultBenefitsMarket = (
    shoppingConfigurationId: ShoppingConfigurationIds | undefined,
    pathwayUser?: IPathwayUserDto
) => {
    if (
        shoppingConfigurationId === ShoppingConfigurationIds.MediShare ||
        [
            PathwayTypes.HealthcareSharingCappedEmployeeCost,
            PathwayTypes.HealthcareSharingCappedEmployerCost,
        ].contains(pathwayUser?.pathwayType)
    ) {
        return BenefitsMarket.SharingPrograms;
    } else if (hasFlag(shoppingConfigurationId, ShoppingConfigurationIds.OffExchange)) {
        return BenefitsMarket.OffExchange;
    }
    return BenefitsMarket.AcaMarketplace;
};

const SILVER_CSR_TOOLTIP =
    "CSR stands for Cost-Sharing Reduction. It's a discount that lowers out of pocket expenses (deductible, copayments, and coinsurance) for Silver plans only.";

export const QUERY_PARAM = {
    CARRIER: 'carrier',
    MARKET: 'market',
    METAL_LEVEL: 'metal-level',
    PLAN_OPTIONS: 'plan-option',
    PLAN_TYPE: 'plan-type',
    SORT: 'sort',
} as const;

type IQueryParamKeys = keyof typeof QUERY_PARAM;
type IQueryParamValues = typeof QUERY_PARAM[IQueryParamKeys];
const ShopFilters = () => {
    const [query, setQuery] = useQuery();
    const { carriers, metalLevels, pathwayUser, pathwayUserData, planTypes } = useSelector(
        (state: AppStore) => {
            const props = {
                carriers: uniqueMarketplaceCarriersSelector(state),
                metalLevels: uniqueMetalLevelsSelector(state),
                pathwayUser: state.pathwayUser,
                pathwayUserData: state.userPathwayBlueprint,
                planTypes: uniquePlanTypesSelector(state),
            };
            if (!props.planTypes.includes('PPO')) {
                props.planTypes.push('PPO');
            }
            return props;
        }
    );
    const shoppingConfigurationId = useShoppingConfiguration();
    const { benefitsMarket } = useContext(ShoppingContext);

    useEffect(() => {
        if (!query.has(QUERY_PARAM.MARKET) && hasValue(pathwayUser?.pathwayType)) {
            const defaultValue = getDefaultBenefitsMarket(
                shoppingConfigurationId,
                pathwayUserData.pathwayUserDto
            );
            query.set(QUERY_PARAM.MARKET, defaultValue);
            setQuery(query);
        }
    }, [pathwayUser, pathwayUserData, query, setQuery, shoppingConfigurationId]);

    const planOptions = ['HSA-Eligible'];
    const handleFilterChange = useCallback(
        (queryParamName) => ({ target: { name } }: React.ChangeEvent<HTMLInputElement>) => {
            query.toggle(queryParamName, name);
            setQuery(query);
        },
        [query, setQuery]
    );
    const marketOptions = useMemo(() => {
        const options = [];
        if (hasFlag(shoppingConfigurationId, ShoppingConfigurationIds.Marketplace)) {
            options.push({
                label: 'ACA Marketplace',
                value: BenefitsMarket.AcaMarketplace,
            });
        }
        if (hasFlag(shoppingConfigurationId, ShoppingConfigurationIds.OffExchange)) {
            options.push({
                label: 'Off-Exchange',
                value: BenefitsMarket.OffExchange,
            });
        }
        if (hasFlag(shoppingConfigurationId, ShoppingConfigurationIds.MediShare)) {
            options.push({
                label: 'Sharing Programs',
                value: BenefitsMarket.SharingPrograms,
            });
        }

        return options;
    }, [shoppingConfigurationId]);

    const setBenefitsMarketQueryParam = useCallback(
        (value) => {
            query.set(QUERY_PARAM.MARKET, value);
            setQuery(query);
        },
        [query, setQuery]
    );

    const renderCheckboxes = useCallback(
        (values: (string | undefined)[], queryParamName: IQueryParamValues) =>
            values.map((value, index) => {
                let name = value;
                const isCSRMetalLevel =
                    queryParamName === QUERY_PARAM.METAL_LEVEL && value?.includes(CSR_LABEL);
                if (isCSRMetalLevel) {
                    name = name?.replace(CSR_LABEL, '');
                }
                const checked = query.getAll(queryParamName).contains(name);
                return (
                    <div className="flex flex-nowrap" key={index}>
                        <Checkbox
                            checked={checked}
                            data-cy={`${queryParamName}-${kebabCase(value)}`}
                            label={value as string}
                            name={name}
                            onChange={handleFilterChange(queryParamName)}
                        />
                        {isCSRMetalLevel && (
                            <span className="ml-1">
                                <InformationIconTooltip title={SILVER_CSR_TOOLTIP} />
                            </span>
                        )}
                    </div>
                );
            }),
        [handleFilterChange, query]
    );
    const renderCarrierCheckboxes = useCallback(() => {
        const carrierNames = (carriers as { name?: string }[]).map((x) => x?.name);
        return renderCheckboxes(carrierNames, QUERY_PARAM.CARRIER);
    }, [carriers, renderCheckboxes]);
    const shouldShowFilters = [BenefitsMarket.AcaMarketplace, BenefitsMarket.OffExchange].includes(
        benefitsMarket as BenefitsMarket
    );

    const showMarketOption =
        !isIchraPathwayType(pathwayUserData.pathwayUserDto?.pathwayType) &&
        getNumberOfSetBitsOnFlag(shoppingConfigurationId);
    return (
        <span data-cy="shop-filters" style={{ color: BODY_FONT_COLOR, maxWidth: '205px' }}>
            {showMarketOption && (
                <React.Fragment>
                    <div className="mt-0 mb-3">
                        <RadioGroup
                            label=""
                            onChange={onChange(setBenefitsMarketQueryParam)}
                            options={marketOptions}
                            value={query.get(QUERY_PARAM.MARKET)}
                        />
                    </div>
                    {shouldShowFilters && <hr />}
                </React.Fragment>
            )}
            {shouldShowFilters && (
                <React.Fragment>
                    <ProviderFilterContent />
                    <Divider />
                    <Stack marginY={1}>
                        <Typography py={1} sx={{ color: HEADER_FONT_COLOR }} variant="h4">
                            Carriers
                        </Typography>
                        {renderCarrierCheckboxes()}
                    </Stack>
                    <Divider />
                    <Stack marginY={1}>
                        <Typography py={1} sx={{ color: HEADER_FONT_COLOR }} variant="h4">
                            Metal Levels
                        </Typography>
                        {renderCheckboxes(metalLevels, QUERY_PARAM.METAL_LEVEL)}
                    </Stack>
                    <Divider />
                    <Stack marginY={1}>
                        <Typography py={1} sx={{ color: HEADER_FONT_COLOR }} variant="h4">
                            Network Types
                        </Typography>
                        {renderCheckboxes(planTypes, QUERY_PARAM.PLAN_TYPE)}
                    </Stack>
                    <Divider />
                    <Stack marginY={1}>
                        <Typography py={1} sx={{ color: HEADER_FONT_COLOR }} variant="h4">
                            Plan Types
                        </Typography>
                        {renderCheckboxes(planOptions, QUERY_PARAM.PLAN_OPTIONS)}
                    </Stack>
                </React.Fragment>
            )}
        </span>
    );
};

export default hot(module)(ShopFilters);
